import * as React from 'react';
import { useState } from 'react';
import { List, ListItemText,
    Box, Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Divider,
    ListItem,
    ListItemButton,
    ListItemSecondaryAction,
    IconButton,
    } from '@mui/material';
import { Client, Patient } from '../../models/core';
import { DateTime } from 'luxon';
import SpinnerButton from '../SpinnerButton';
import { GridFormItem } from '../GridForm';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { CheckBoxSharp } from '@mui/icons-material';
import { API as ZiphyAPI, Filter, ListPatientsResult } from '../../utils/ZiphyAPI';
import { API } from '../../utils/Api'

import { Patient as ZiphyPatient, Date as ZiphyDate } from "../../models/ziphy";
import useSnackbar from '../../hooks/useSnackbar';
import AlertSnack from '../ErrorSnack';
import SimpleDialog from '../dialogs/SimpleDialog';
import useDialog from '../../hooks/useDialog';


interface PatientListItemProps {
    patient: ZiphyPatient;
    isSelected: boolean;
    onClick?: (patient: ZiphyPatient) => void;
}

const PatientItem = ({ patient, isSelected, onClick, ...props }: PatientListItemProps): JSX.Element => {
    return (
        <ListItem {...props} >
            <ListItemButton disableRipple onClick={() => { onClick && onClick(patient)}} selected={isSelected}>
                <ListItemText
                    primary={<Typography component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                        {patient.last_name}, {patient.first_name} ({
                            patient.dob && (`${patient.dob.month}-${patient.dob.day}-${patient.dob.year}`) || 'No DoB'})
                    </Typography>}
                    secondary={
                    <span>
                        Patient ID: {patient?.id || 'unknown'}
                        <br/>
                        Practice Id: {patient.practice_ids || 'unknown'}
                    </span>} />
                <ListItemSecondaryAction>
                    { isSelected ? <IconButton><CheckBoxSharp/></IconButton> : <></> }
                </ListItemSecondaryAction>
            </ListItemButton>
        </ListItem>
    );
};


interface PatientSearchViewProps {
    open: boolean;
    client: Client;
    patient: Patient;
    onUpdate:(zcmId:string, updatedPatient:Patient) => void;
    onClose:() => void;
    onCreatePatient?:() => void;
}

const ZiphyPatientSearchDialog = ({open, client, patient, onUpdate, onClose, onCreatePatient}: PatientSearchViewProps): JSX.Element => {

    const [firstName, setFirstName] = useState<string>(patient?.firstName || '');
    const [lastName, setLastname] = useState<string>(patient?.lastName || '');
    const [dob, setDob] = useState<DateTime|undefined>(DateTime.fromISO(patient?.dob || ''));
    const [zcmId, setZCMID] = useState<string>(patient?.zcmId || '');
    
    const [searching, setSearching] = useState<boolean>(false);
    const [selectedPatientId, setSelectedPatientId] = useState<string>();
    const [filteredPatients, setFilteredPatients] = useState<ZiphyPatient[]|undefined>();
    
    const snackBar = useSnackbar();
    const [snackBarError, setSnackBarError] = useState<string>();
    const [snackBarAlert, setSnackBarAlert] = useState<string>();
    const { show:showComDiag, hide:hideComDiag, open:comDiagIsOpen } = useDialog();

    React.useEffect(() => {
        if(snackBarError) {
            snackBar.showError(new Error(snackBarError));
            setSnackBarError(undefined);
        }
        if(snackBarAlert) {
            snackBar.showSuccess(snackBarAlert);
            setSnackBarAlert(undefined);
        }
    },[snackBarError, snackBarAlert, snackBar])

    const str2date = React.useCallback((date: Date):ZiphyDate => {
        return { _type: 'date', year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
    },[]);

    const requestFilteredPatients = React.useCallback(async () => {
        const filter:Filter = (zcmId?.trim()) ? {
            and: [
                { eq: ['is_hidden', false] },
                { eq: ["id", Number.parseInt(zcmId)] },
                { contains: ["practice_ids", [client.practiceId]] }
            ]
        } : {
            and: [
                { eq: ['is_hidden', false] },
                {
                    and:[
                        (firstName?.trim() ? { imatch: ['first_name', firstName] } : {}),
                        (lastName?.trim() ? { imatch: ['last_name', lastName] } : {}),
                        (dob ? { eq: ['dob', { _type: 'date', year: dob.year, month: dob.month, day: dob.day }] } : {})
                    ]
                },
                { contains: ["practice_ids", [client.practiceId]] }
            ]
        }
            
        try {
            const request:ListPatientsResult = await ZiphyAPI.getPatients(filter);
            setFilteredPatients(request?.patients?.items || []);
        }catch(error) {
            console.log(error);
            setSnackBarError('Failed to retrieve Users.');
        }
        setSearching(false);

    }, [client, firstName, lastName, zcmId, str2date, dob])

    const patientListComponant = React.useMemo(() => {
        return <List>
            {
                (filteredPatients || []) .map((m, i) => {
                    const targetId = m.id?.toString();
                    const isSelected = selectedPatientId != undefined && targetId != undefined && selectedPatientId == targetId;
                    return <PatientItem  key={'pmid-'+i} patient={m} 
                        isSelected={isSelected}
                        onClick={() => setSelectedPatientId(isSelected ? undefined : targetId?.toString()) }/>
                })
            }
        </List>
    }, [filteredPatients, selectedPatientId]);

    const updatePatientID = React.useCallback(async (patient:Patient, newZCMID:string) => {
        const updatedPatient = { ...patient, ...{ zcmId:newZCMID }};
        try {
            const request = await API.updatePatient(updatedPatient);
            if(!request) {
                throw Error('Failed to create new patient');
            }
            onUpdate && onUpdate(newZCMID, updatedPatient);
            setZCMID(newZCMID);
            setSnackBarAlert(`Successfully updated ${updatedPatient.lastName},${updatedPatient.firstName} ID to ${newZCMID}`);

            await requestFilteredPatients();
        }catch(error) {
            console.log(error);
            setSnackBarError(`Failed to update ${updatedPatient.lastName},${updatedPatient.firstName} with ${newZCMID}`);
        }
    },[onUpdate,requestFilteredPatients]);

    const confirmLinkPatientBox = React.useCallback((linkingPatient:Patient, onCancel:()=>void, onConfirm:()=>void) => {
        return (
            <SimpleDialog open={true} title={<b>{'Attention'}</b>}
                body={
                    <>
                        <Typography variant="body1" align="center"> {`You are about create a new Ziphy Medical Patient`}</Typography>
                        <Typography variant="body1" align="center"> {`using Outreach Patient: ${linkingPatient?.lastName}, ${linkingPatient?.firstName}.`} </Typography>
                        <Typography variant="body1" align="center"> {'This cannot be undone.'} </Typography>
                        <br/>
                        <Typography variant="body1" align="center"> {'Do you want to continue?'} </Typography>
                    </>
                }
                buttons={[
                    {text:'Cancel', onClick:onCancel },
                    {text:'Create Patient', onClick:onConfirm }
                ]}
        />);
    },[]);

    const createZiphyPatient = React.useCallback(async (patient:Patient, practiceId:number) => {
        const primePhoneNumber = patient.phone?.startsWith('+1')
            ? patient.phone
            : patient.phone?.startsWith('1')
                ? ('+' + patient.phone)
                : patient.phone
                    ? '+1' + patient.phone
                    : undefined;

        const altPhoneNumber = patient.phone2?.startsWith('+1')
            ? patient.phone
            : patient.phone?.startsWith('1')
                ? ('+' + patient.phone)
                : patient.phone
                    ? '+1' + patient.phone
                    : undefined;
        
        try {
            const request = await ZiphyAPI.createPatient(
                { dob:str2date(new Date(patient.dob || '')), code:'', 
                    first_name:patient.firstName, last_name:patient.lastName,
                    phone:altPhoneNumber?.replaceAll(/[^0-9+]/g, ''), 
                    email:patient.email, gender:patient.gender?.toLowerCase(), 
            }, practiceId);
            if(!request) {
                throw Error('Failed to create ziphy patient');
            }

            const newZCMID = request.id?.toString() || '';
            const numZCMID = Number.parseInt(newZCMID);

            try {
                if(primePhoneNumber) {
                    const upReq = await ZiphyAPI.createContact(numZCMID, {
                        system:'phone',
                        use:'home',
                        value:primePhoneNumber
                    });
                    if(!upReq) {
                        throw Error('Failed to create new patient');
                    }
                }
            }catch(error) {
                console.log(error);
                setSnackBarError(`Failed to Add Phone Number: ${patient.phone}. Please add in Ziphy Admin`);
            }

            try {

                const lines = [ patient.address || '', patient.address2 || '' ].filter(e => e && e != '');

                const upReq = await ZiphyAPI.createAddresses(numZCMID, {
                    city: patient.city,
                    country: patient.county,
                    state: patient.state,
                    line: lines,
                    postalCode: patient.zip,
                    use:'home',
                    type:'physical'
                });
                if(!upReq) {
                    throw Error('Failed to create naddress');
                }
            }catch(error) {
                console.log(error);
                setSnackBarError(`Failed to Add Phone Number: ${patient.phone}. Please add in Ziphy Admin`);
            }

            const updatePatient = {...patient, ...{zcmId:newZCMID}};
            const requestUpdate = await API.updatePatient(updatePatient);
            if(!requestUpdate) {
                throw Error('Failed to create new patient');
            }
            onUpdate && onUpdate(newZCMID, updatePatient);
            onCreatePatient && onCreatePatient();

            setZCMID(newZCMID);
            setSnackBarAlert(`Successfully Create Ziphy Patient ${updatePatient.lastName},${updatePatient.firstName} ID to ${newZCMID}`);

        } catch(error) {
            console.log(error);
            setSnackBarError(`Failed to create patient: ${patient.lastName},${patient.firstName}`);
        }
    }, [onUpdate, str2date]);

    return (
        <Dialog
            open={open}
            PaperProps={ { sx: { height:'60%', maxHeight:'60%', width: '50%', maxWidth: '60%', minWidth:'300px' } }}
        >
            <DialogTitle>Patient Search</DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ width: '25%', minWidth:'150px', borderRight: '1px solid #ccc', padding: '4px', paddingRight:'15px', paddingBottom:'8px' }}>
                    <GridFormItem xs={12}>
                        <GridFormItem xs={12}>
                            <TextField name='zcmId' label='ZCM Patient ID' value={zcmId}
                                onChange={(val) => setZCMID(val.target.value) } fullWidth
                                variant='standard' />
                        </GridFormItem>
                        <Divider/>
                        <GridFormItem xs={12}>
                            <TextField name='firstName' label='First Name' value={firstName}
                                onChange={(ev) => setFirstName(ev.target.value || '')} fullWidth
                                variant='standard' />
                        </GridFormItem>
                        <Divider/>
                        <GridFormItem xs={12}>
                            <TextField name='lastName' label='Last Name' value={lastName}
                                onChange={(ev) => setLastname(ev.target.value || '')} fullWidth
                                variant='standard' />
                        </GridFormItem>
                        <Divider/>
                        <GridFormItem xs={12}>
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                                <DatePicker label='Date of Birth' inputFormat='yyyy-MM-dd'
                                    value={ dob }
                                    renderInput={
                                        (params) => <TextField name='dob' required={false} {...params} fullWidth
                                        variant='standard' helperText='Format: YYYY-MM-DD' error={false}/>
                                    }
                                    onChange={ (e) => setDob(e||undefined) } />
                            </LocalizationProvider>
                        </GridFormItem>
                        <Divider/>
                        <GridFormItem xs={12}><Box sx={{ height: '1em' }} /></GridFormItem>
                        <GridFormItem xs={12}>
                            <SpinnerButton onClick={ requestFilteredPatients }
                                showSpinner={searching}>Search For Patients</SpinnerButton>
                        </GridFormItem>
                    </GridFormItem>
                </Box>
                <Box sx={{ width: '75%', padding: '16px' }}>
                    {
                        (filteredPatients && filteredPatients.length > 0) ? patientListComponant :
                        <Box display="flex" justifyContent="center" alignItems="center" height='80%'>
                            <Box width={'60%'} height={'60%'} display="flex" justifyContent="center" alignItems="center"
                                border={1} borderRadius={1} boxShadow={1} p={2}>
                                <Typography variant="h6" align="center">
                                    {'No Patients Found.'}
                                    <br/>
                                    {'Set search filters and press search to proceed.'}
                                </Typography>
                            </Box>
                        </Box>
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
                <Button onClick={() => selectedPatientId && updatePatientID(patient, selectedPatientId) } disabled={(selectedPatientId == undefined || selectedPatientId == '')}>
                    Link Patient
                </Button>
                { onCreatePatient && <Button disabled={ (patient.zcmId && patient.zcmId != '') || onCreatePatient == null} onClick={showComDiag}>New Patient</Button> }
            </DialogActions>
            { ((!patient.zcmId || patient.zcmId == '') && comDiagIsOpen) && confirmLinkPatientBox(patient, hideComDiag, async () => {
                await createZiphyPatient(patient, client.practiceId);
                hideComDiag();
            })}
            <AlertSnack {...snackBar}/>
        </Dialog>
    );
}

export default ZiphyPatientSearchDialog;
