import { SyntheticEvent, useCallback } from 'react';
import { DateTime } from 'luxon';
import useSearch from '../hooks/useSearch';
import { Appointment, Patient as ApiPatient, AptDate } from '../models/ziphy';
import { API, Filter } from '../utils/ZiphyAPI';
import { Client, Patient } from '../models/core';


export interface AppointmentExt extends Appointment {
    patient: ApiPatient;
    // agent: 
}

interface AptFilter {
    names: string[];
    date: DateTime | null;
    id: number | null;
}

/*function text2filter(text: string): AptFilter {
    return text.split(' ').filter(Boolean).reduce((acc, word) => {
        if (word.match(/\d\d?\/\d\d?\/\d\d\d\d/)) {
            acc.date = DateTime.fromFormat(word, 'M/d/yyyy');
        }
        else if (word.match(/^\d+$/)) {
            acc.id = Number.parseInt(word, 10);
        }
        else if (word.match(/^[a-zA-Z]{3,}$/)) {
            acc.names = [...acc.names, word];
        }
        return acc;
    }, { names: [], date: null, id: null } as AptFilter);
}*/

/*async function makeFilter(names: string[], scheduled1: DateTime | null, id: number | null, practiceId?: number): Promise<Filter> {
    let filters: Filter[] = [{ eq: ['id', id] }];
    if (id != null) {
        filters = [...filters, { eq: ['id', id] }];
    }
    else {
        filters = practiceId ? [
            { eq: ["practice_id", practiceId] }
        ] : [];
        const response = names.length > 0
            ? await API.getPatients(
                {
                    and: [
                        { eq: ['is_hidden', false] },
                        { eq: ["id", id] },
                        { contains: ["practice_ids", [practiceId]] }
                    ]
                })
            : null;
        if (response && response.patients && response.patients.count > 0) {
            filters = [
                ...filters,
                {
                    or: response.patients.items.map((p): Filter => ({
                        eq: ["patient_id", p.id]
                    }))
                }
            ]
        }
        if (scheduled1 instanceof DateTime) {
            const t1 = { ...scheduled1.toObject(), hour: 0, minute: 0, millisecond: undefined, microsecond: 0, utcoffset: 0, _type: 'datetime' }
            const t2 = { ...scheduled1.toObject(), hour: 23, minute: 59, millisecond: undefined, microsecond: 0, utcoffset: 0, _type: 'datetime' }
            filters = [
                ...filters,
                { "gte": ["appointed_start", t1] },
                { "lte": ["appointed_start", t2] }
            ];
        }
    }
    return { and: filters };
}*/
function ziphydate2dt(t: AptDate) {
    return DateTime.fromObject({
        year: t.year, month: t.month, day: t.day, hour: t.hour, minute: t.minute, second: t.second
    });
}


export default function useAptSearch(
    enabled: boolean,
    client: Client | null, 
    patient: Patient | null, 
    scheduled1: DateTime | null, 
    appointment: AppointmentExt | null): {
        apt: AppointmentExt | null,
        apts: AppointmentExt[],
        onAptChange: (_event: SyntheticEvent<Element, Event>, value: AppointmentExt | null) => void,
        onAptInputChange: (event: SyntheticEvent<Element, Event>, value: string) => void,
        aptLabel: (apt: AppointmentExt) => string,
        compareApts: (x: AppointmentExt, y: AppointmentExt) => boolean
    } {

    const fetch = useCallback(async (text: string): Promise<AppointmentExt[]> => {
        if (client && patient && enabled && patient.zcmId && patient.zcmId != '') {
            //const f = text2filter(text);
            const filter:Filter = {and:[{ eq: ['id', patient.zcmId] }]}
             /*(f.id != null || f.date != null || f.names.length > 0)
                ? await makeFilter(f.names, f.date, f.id, client.practiceId)
                : await makeFilter([patient.firstName, patient.lastName], scheduled1 || null, null, client.practiceId)*/
            if (filter.and || filter.or) {
                try {
                    const response = await API.getAppointments(filter);
                    if (response && response.expanded && response.expanded.patients) {
                        const patients = Object.fromEntries(response.expanded.patients.items.map(({ key, value }) => [key + '', value]));
                        return response.appointments.items.map(apt => ({ ...apt, patient: patients[apt.patient_id + ''] }));
                    }
                } catch (err: unknown) {
                    console.error((err instanceof Error) ? err.message : 'useAptSearch: Unknown error has occured');
                }
            }
        }
        return [];
    }, [client, patient, scheduled1, enabled]);

    const aptLabel = useCallback((option: AppointmentExt) => (
        option?.patient && `${option?.patient?.first_name} ${option?.patient?.last_name} at ${option?.appointed_start && ziphydate2dt(option?.appointed_start).toLocaleString() || ''} (${option.id})`
    ), []);

    const {
        options: apts,
        value: apt,
        onChange: onAptChange,
        onInputChange: onAptInputChange
    } = useSearch<AppointmentExt>(fetch, appointment, aptLabel);

    const compareApts = useCallback((x: AppointmentExt, y: AppointmentExt) => (
        x.id == y.id
    ), []);

    return {
        apt, apts, onAptChange, onAptInputChange, aptLabel, compareApts
    };
}