import * as React from 'react';
import { useCallback, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, 
    MenuItem, Paper, Select, SelectChangeEvent, Snackbar, Stack, StackProps, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import ExploreIcon from '@mui/icons-material/Explore';
import SearchIcon from '@mui/icons-material/Search';
import TableRowsIcon from '@mui/icons-material/TableRows';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { Client, ClusterJob, ClusterJobFilter, ClusterJobParams, ClusterJobResult, Job, JobStatus, PatientStatus, Plan } from '../models/core';
import { DateTime } from 'luxon';
import { API } from '../utils/Api';
import { useTheme } from '@mui/material';
import ClusterMap from './ClusterMap';
import { Wrapper } from '@googlemaps/react-wrapper';
import { VisitStatuses } from '../utils/enums';
import FiltersPanel, { FilterField } from './FiltersPanel';
import debounce from 'lodash.debounce';
import { InfoButton } from './InfoButton';
import JobsList from './schedule/JobsList';
import { JobWithMessages, useJobsList } from '../hooks/useJobsList';
import { Message } from './schedule/MessagesBox';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ClustersTableView = ({ result }: { result?: ClusterJobResult }): JSX.Element => {
    const tableColumns = React.useMemo(() =>
        ['INSURANCE ID', 'Member First Name', 'Member Last Name', 'Member Address 1', 'MemberCity', 
        'MemberState', 'MemberZip', 'MemberPhone 1', 'MemberPhone 2', 'Cluster Index', 'Points in Cluster', 
        `Cluster Size (${result?.clusters[0]?.radius?.units})`]
        , [result]);
    const tableContent = React.useMemo(() =>
        result?.clusters?.flatMap(({ points, radius }, i) =>
            points.map(pt =>
                [
                    pt.patient?.refId, pt.patient?.firstName,
                    pt.patient?.lastName, pt.patient?.address,
                    pt.patient?.city, pt.patient?.state, pt.patient?.zip,
                    pt.patient?.phone, pt.patient?.phone2, i + 1, points.length,
                    `${radius.value?.toFixed(2)}`
                ] as string[]
            )
        ) || []
        , [result]);
    return <Box sx={{
        maxHeight: '640px',
        overflowY: 'auto',
        table: {
            margin: '8px',
            whiteSpace: 'nowrap',
            'td,th': {
                borderTop: `1px dotted gray`,
                borderLeft: `1px dotted gray`,
                padding: '0 4px'
            },
            'td:last-child, th:last-child': {
                borderRight: `1px dotted gray`,
            },
            'tr:last-child td': {
                borderBottom: `1px dotted gray`,
            }
        }
    }}>
        <table>
            <thead>
                <tr>
                    {tableColumns.map((col, i) =>
                        <th key={i}>{col}</th>
                    )}
                </tr>
            </thead>
            <tbody>
                {tableContent.map((row, i) =>
                    <tr key={i}>
                        {row.map((cell, j) =>
                            <td key={j}>{cell}</td>
                        )}
                    </tr>

                )}
            </tbody>
        </table>
    </Box>
}

const ResultView = ({ result, time }: { result: ClusterJobResult, time?: string }): JSX.Element => {
    const [viewMode, setViewMode] = useState('table');

    const onViewModeChange = useCallback((event: React.MouseEvent<HTMLElement>, mode: string | null) => {
        setViewMode(mode || 'table');
    }, []);

    return <>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', marginBottom: '4px' }}>
            {
                time && <Typography variant='body1'>{time}</Typography>
            }
            <div style={{ flexGrow: 1 }}></div>
            <ToggleButtonGroup value={viewMode} exclusive onChange={onViewModeChange}>
                <ToggleButton value={'table'}><TableRowsIcon /></ToggleButton>
                <ToggleButton value={'map'}><ExploreIcon /></ToggleButton>
            </ToggleButtonGroup>
        </Box>
        <Box sx={{ width: '800px', height: '640px' }}>
            {viewMode == 'table'
                ? <ClustersTableView result={result} />
                :
                <Wrapper
                    id='google-maps'
                    apiKey={process.env.GOOGLE_MAPS_API_KEY || ''}
                    libraries={['places']}
                >
                    <ClusterMap result={result} />
                </Wrapper>
            }
        </Box>
    </>
}

export interface ClusterDialogProps extends DialogProps {
    open: boolean;
    client: Client;
    plans: Plan[];
    onClose?: () => void;
}
const NoContentBox = ({ children }: { children?: React.ReactNode }): JSX.Element => (
    <Box sx={{
        height: '692px',
        border: '2px solid #cad8ff',
        color: '#7d94d3',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        'svg': {
            fontSize: '3em',
            marginBottom: '0.3em'
        }
    }}>
        {children}
    </Box>
)

const MATCHED_PATIENTS_WARN = 1000;
const MATCHED_PATIENTS_ERROR = 2000;

const MIN_POINTS = 5;
const MAX_POINTS = 25;
const DEFAULT_MIN_SIZE = 5;
const MIN_SIZE = 0.01;
const MAX_SIZE = 25;


interface JobsListHeaderProps extends StackProps {
    clientId:string;
    job: JobWithMessages<ClusterJob>|null;
    onRunClick?: () => void;
    onStopClick?: () => void;
    onCreateClick?: (job:Omit<Job, '_id'>) => void;
    onDeleteClick?: () => void;
}

const JobsListHeader = ({ clientId, job, onRunClick, onStopClick, onCreateClick, onDeleteClick }: JobsListHeaderProps): JSX.Element => {
    const buttons = React.useMemo(() => {
        const t = [JobStatus.IN_PROGRESS, JobStatus.TERMINATING];
        return [
            ['create', true],
            ['delete', job && !t.includes(job.status)],
            ['run', job && !t.includes(job.status) && job.messages.filter(m => m.severity == 'error').length == 0],
            ['stop', job && job.status == JobStatus.IN_PROGRESS]
        ].filter(([_,v]) => v).map(([k]) => k);
    }, [job]);
    return (
        <Stack direction='row' sx={{ alignItems: 'center', height: '63px' }}>
            <Typography variant='h6' sx={{ flexGrow: 1, paddingLeft: '8px' }}>Variants</Typography>
            <IconButton
                onClick={onRunClick}
                disabled={!buttons.includes('run')}
                sx={{ display: (buttons.includes('run') || !buttons.includes('stop')) ? undefined : 'none' }}
            >
                <PlayArrowIcon />
            </IconButton>
            <IconButton
                onClick={onStopClick}
                sx={{ display: buttons.includes('stop') ? undefined : 'none' }}
            >
                <StopIcon />
            </IconButton>
            <IconButton onClick={
                () => {
                    const newJob = {
                        clientId: clientId,
                        type: 'cluster',
                        name: 'New Job',
                        status: JobStatus.NEW,
                        state:'New',
                        params: {
                            maxPoints:MAX_POINTS,
                            minPoints:MIN_POINTS,
                            maxSize:MAX_SIZE,
                            minSize:DEFAULT_MIN_SIZE,
                            units:'mi',
                            filter:[
                                {
                                    field:'status',
                                    values:['Active']
                                }
                            ]
                        }
                    } as Omit<Job,'_id'>;
                    onCreateClick && onCreateClick(newJob);
                }
            } disabled={!buttons.includes('create')}>
                <AddIcon />
            </IconButton>
            <IconButton onClick={onDeleteClick} disabled={!buttons.includes('delete')}>
                <DeleteIcon />
            </IconButton>
        </Stack>

    );
}

function jobMessages(job: ClusterJob): Message[] {
    return [];
}

const ClusterDialog = ({ open, client, plans, onClose, ...props }: ClusterDialogProps): JSX.Element => {

    const { jobs: allJobs, isLoading, createJob, saveJob, isJobSaving, deleteJob, runJob, stopJob, activeJob, setActiveJob }
        = useJobsList<ClusterJob>(client._id, 'cluster', jobMessages);

    const [jobName, setJobName] = useState(activeJob?.name || 'New Job')
    const [minSize, setMinSize] = useState((activeJob?.params.minSize || DEFAULT_MIN_SIZE) + '');
    const [maxSize, setMaxSize] = useState((activeJob?.params.maxSize || MAX_SIZE) + '');
    const [minPoints, setMinPoints] = useState(activeJob?.params.minPoints || MIN_POINTS);
    const [maxPoints, setMaxPoints] = useState(activeJob?.params.maxPoints || MAX_POINTS);
    const [units, setUnits] = useState(activeJob?.params.units || 'mi');
    const [snackOpen, setSnackOpen] = useState(false);
    const [filteredCount, setFilteredCount] = useState<number | null>(null);
    const [activeJobId, setActiveJobId] = useState<string>(activeJob?._id || '');
    const theme = useTheme();
    const [filterValue, setFilterValue] = useState<{ [k: string]: string | string[] }>(() => ({
        'city': [],
        'zip':['all'],
        'customFields.County': ['all'],
        'planId': ['all'],
        'customFields.RiskCategory': ['all'],
        'visit.status': ['New', 'In Progres'],
    }));
    const [cities, setCities] = useState<string[]>([]);
    const [counties, setCounties] = useState<string[]>([]);
    const [zips, setZips] = useState<string[]>([]);

    const [jobNeedsSaved, setJobNeedsSaved] = useState<boolean>(false);

    React.useEffect(() => {

        if(activeJobId != activeJob?._id) {
            setActiveJobId(activeJob?._id || '');
            setJobNeedsSaved(false);
        }

        setMinSize((activeJob?.params.minSize || DEFAULT_MIN_SIZE) + '');
        setMaxSize((activeJob?.params.maxSize || MAX_SIZE) + '');
        setMinPoints(activeJob?.params.minPoints || MIN_POINTS);
        setMaxPoints(activeJob?.params.maxPoints || MAX_POINTS);
        setUnits(activeJob?.params.units || 'mi');
        setJobName(activeJob?.name || 'New Job');

        function filterMap(filters:ClusterJobFilter[], fieldName:string, def?:string[]):string[] {
            return filters.filter(e => e.field == fieldName).flatMap(e => e.values) || def || [];
        }

        const filters = activeJob?.params?.filter || [];
        const zip = filterMap(filters, 'zip');
        const county = filterMap(filters, 'customFields.County');
        const plans = filterMap(filters, 'planId');
        const risks = filterMap(filters, 'customFields.RiskCategory');
        const status = filterMap(filters, 'visit.status', ['New', 'In Progres']);

        setFilterValue({
            'city': filterMap(filters, 'city'),
            'zip': zip.length == 0 ? ['all'] : zip,
            'customFields.County': county.length == 0 ? ['all'] : county,
            'planId': plans.length == 0 ? ['all'] : plans,
            'customFields.RiskCategory': risks.length == 0 ? ['all'] : risks,
            'visit.status': status.length == 0 ? ['all'] : status,
        });
    }, [activeJob, activeJobId]);

    React.useEffect(() => {
        if(!activeJob) {
            if(allJobs?.length > 0) {
                setActiveJob(allJobs[0]);
            }
        }
    }, [activeJob, allJobs, setActiveJob])

    React.useEffect(() => {
        const fetch = async () => {
            const [cities, counties, zipcode] = await Promise.all([
                API.getPatientValues(client._id, 'city'),
                API.getPatientValues(client._id, 'customFields.county'),
                API.getPatientValues(client._id, 'zip'),
            ]);
            setCities(cities.filter(Boolean));
            setCounties(counties.filter(Boolean));
            setZips(zipcode.filter(Boolean));
        };
        if (open) {
            fetch().catch(console.error);
        }
    }, [client, open])

    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {

        function extractValidFloat(input: string): string {
            const sanitized = input.replace(/[^0-9.]/g, ''); // Keep only digits and '.'
            const parts = sanitized.split('.'); // Split by '.'
            return parts[0] + (parts.length > 1 ? '.' + parts.slice(1).join('') : '');
          }

        switch (event.currentTarget.name) {
            case 'min-size': setMinSize(extractValidFloat(event.target.value)); break;
            case 'max-size': setMaxSize(extractValidFloat(event.target.value)); break;
            case 'min-points': setMinPoints(Number.parseInt(event.target.value)); break;
            case 'max-points': setMaxPoints(Number.parseInt(event.target.value)); break;
        }
        setJobNeedsSaved(true);
    }, []);

    const onSelectChange = useCallback((event: SelectChangeEvent) => {
        setUnits(event.target.value);

        setJobNeedsSaved(true);
    }, []);


    const createJobParams = useCallback(() => {
        const nMinSize = Number.parseFloat(minSize);
        const nMaxSize = Number.parseFloat(maxSize);

        const params = {
            maxPoints, maxSize:nMaxSize, minPoints, minSize:nMinSize, units,
            filter: Object.entries({
                ...filterValue,
                'status':[PatientStatus.ACTIVE.toString()],
                'planId': filterValue['planId'].includes('all')
                    ? ['all']
                    : plans.filter(p => filterValue['planId'].includes(p.name)).map(p => p._id),
            })
                .filter(([_, values]) => !['', 'all'].includes((values as string[]).join('')))
                .map(([field, values]) => ({ field, values }))
        } as ClusterJobParams;

        return {
            name: jobName,
            params: params,
        }
    }, [maxPoints, jobName, maxSize, minPoints, minSize, units, filterValue, plans]);


    const onSaveClick = useCallback(async () => {
        if(!activeJob) {
            return;
        }
        const newJobSave = {
            ...activeJob,
            ...createJobParams()
        };
        saveJob(newJobSave);
        await isJobSaving || isLoading

        setJobNeedsSaved(false);
        
    }, [activeJob, saveJob, createJobParams, isJobSaving, isLoading]);

    const onRunClick = useCallback(async () => {
        if(activeJob && !isJobSaving) {
            runJob();
        }

    }, [activeJob, runJob, isJobSaving]);

    const tableColumns = React.useMemo(() =>
        ['INSURANCE ID', 'Member First Name', 'Member Last Name', 'Member Address 1', 'MemberCity', 'MemberState', 'MemberZip', 'MemberPhone 1', 'MemberPhone 2', 'Cluster Index', 'Points in Cluster', `Cluster Size (${activeJob?.result?.clusters ? activeJob?.result?.clusters[0]?.radius?.units : 'None'})`]
        , [activeJob]);
    const tableContent = React.useMemo(() => {
        return activeJob?.result?.clusters?.flatMap(({ points, radius }, i) =>
            points.map(pt =>
                [
                    pt.patient?.refId, pt.patient?.firstName,
                    pt.patient?.lastName, pt.patient?.address,
                    pt.patient?.city, pt.patient?.state, pt.patient?.zip,
                    pt.patient?.phone, pt.patient?.phone2, i + 1, points.length,
                    radius.value?.toFixed(4)
                ] as string[]
            )
        ) || [];
    }, [activeJob])

    const onExportClick = useCallback(async () => {
        navigator.clipboard.writeText(
            '"' + tableColumns.join('","') + '"\n' +
            tableContent?.map(row => '"' + row.join('","') + '"').join('\n')
        );
        setSnackOpen(true);
    }, [tableColumns, tableContent]);

    const onSnackClose = useCallback((event: React.SyntheticEvent | Event, reason: string) => {
        if (reason != 'clickaway') {
            setSnackOpen(false);
        }
    }, []);

    React.useEffect(() => {
        if (!open) {
            setSnackOpen(false);
        }
    }, [open]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetch = React.useCallback(debounce(async (filterValue: { [k: string]: string | string[] }) => {
        function maybeJoin(x: string | string[]): string {
            return x == 'all' ? '' : (x instanceof Array) ? (x.includes('all') ? 'all' : x.join('|')) : x;
        }
        const patients = await API.getPatients(
            client._id,
            Object.entries({
                ...filterValue,
                'status':[PatientStatus.ACTIVE.toString()],
                'planId': filterValue['planId'].includes('all')
                    ? ['all'] // for maybeJoin
                    : plans.filter(p => filterValue['planId'].includes(p.name)).map(p => p._id)
            }).map<[string, string]>(([k, v]) => [k, maybeJoin(v)]).filter(([_, v]) => !!v),
            10, 0, [['name', 1]]
        );
        setFilteredCount(patients.count);
    }, 250), [client]);

    React.useEffect(() => {
        fetch(filterValue);
    }, [fetch, filterValue]);

    const onFiltersChange = React.useCallback((value: { [k: string]: string | string[] }) => {
        function maybeJoin(x: string | string[]): string | string[] {
            return (x instanceof Array && x.includes('all')) ? ['all'] : x;
        }
        setFilterValue({
            'city': maybeJoin(value['city']) || ['all'],
            'customFields.County': maybeJoin(value['customFields.County']) || ['all'],
            'planId': maybeJoin(value['planId']) || ['all'],
            'customFields.RiskCategory': maybeJoin(value['customFields.RiskCategory']) || ['all'],
            'visit.status': maybeJoin(value['visit.status']) || ['all'],
        })

        setJobNeedsSaved(true);
    }, []);

    const filterFields = React.useMemo<FilterField[]>(() => [
        { name: 'city', type: 'autocomplete', items: cities, emptyLabel: 'Any City', width: 6 },
        { name: 'zip', type: 'enum', emptyLabel: 'Any Zip', items: zips,  width: 6 },
        {
            name: 'customFields.County', type: 'enum', emptyLabel: 'Any County',
            items: counties,
            disabled: !client.layout.patientFields.find(f => f.label == 'County'), width: 6
        },
        { name: 'planId', type: 'enum', items: plans.map(p => p.name), emptyLabel: 'Any Plan', width: 6 },
        {
            name: 'customFields.RiskCategory', type: 'enum', items: ['Top 1%', 'Top 2-5%', 'Top 6-20%', 'Bottom 80%'], emptyLabel: 'Any Risk',
            disabled: !client.layout.patientFields.find(f => f.label == 'RiskCategory'), width: 6
        },
        {
            name: 'visit.status', type: 'enum', items: VisitStatuses,
            emptyLabel: 'Any Status', width: 6
        },
    ], [cities, client.layout.patientFields, counties, plans, zips]);

    const isProcessLoading = (filteredCount || 0) >= MATCHED_PATIENTS_ERROR 
        || !activeJob || activeJob?.status == JobStatus.IN_PROGRESS || activeJob?.status == JobStatus.TERMINATING
        || isLoading || isJobSaving;

    return (
        <Dialog {...props} open={open} onClose={onClose} PaperProps={{ sx: { width: '1200px', maxWidth: '1200px' } }}>
            <DialogTitle>Cluster</DialogTitle>
            <DialogContent>
                <Stack direction='row' width='1160px'>
                <Stack direction='column' width='330px'>
                        <JobsListHeader
                            clientId={client._id}
                            job={activeJob}
                            onCreateClick={createJob}
                            onDeleteClick={deleteJob}
                            onRunClick={runJob}
                            onStopClick={stopJob}
                        />
                        <JobsList
                            jobs={allJobs}
                            selectedJob={activeJob}
                            onSelectJob={setActiveJob}
                            isLoading={isLoading}
                            isJobSaving={isJobSaving}
                            sx={{ width: '300px', flexGrow: 1 }}
                        />
                </Stack>
                <Stack direction='column' width='800px'>
                <Stack direction='row' width='800px'>
                    <Stack direction='column' width='700px'>
                        <TextField label={'Job Name'} name={'job-name'} value={jobName} onChange={(e) => {
                            setJobName(e.target.value) 
                            setJobNeedsSaved(true);
                        }} variant='standard'/> 
                    
                        <FiltersPanel padding={0} marginBottom='16px' direction='row' fields={filterFields} 
                            defaultValue={filterValue} value={filterValue} onChange={onFiltersChange} />
                        <Typography sx={{
                            visibility: filteredCount == null ? 'hidden' : 'visible',
                            marginBottom: '16px',
                            color: (filteredCount || 0) > MATCHED_PATIENTS_ERROR
                                ? theme.palette.error.main
                                : (filteredCount || 0) > MATCHED_PATIENTS_WARN
                                    ? theme.palette.warning.main
                                    : undefined
                        }}>Matched {filteredCount || 0} patients{
                                (filteredCount || 0) > MATCHED_PATIENTS_ERROR
                                    ? '. Apply more filters to reduce match count.'
                                    : (filteredCount || 0) > MATCHED_PATIENTS_WARN
                                        ? '. Clustering will be slow.'
                                        : undefined

                            }</Typography>
                        <Stack direction='row' sx={{
                            alignItems: 'baseline',
                            '.MuiFormControl-root': {
                                width: '3em'
                            },
                            'span': {
                                marginLeft: '4px',
                                marginRight: '4px'
                            },
                            '.MuiGrid-item': {
                                display: 'flex',
                                alignItems: 'baseline'
                            },
                            'input[type="text"]': {
                                textAlign: 'right',
                                paddingRight: '4px'
                            }
                        }}>
                            <Typography>Cluster size</Typography>
                            <InfoButton sx={{ alignSelf: 'center', cursor: 'help', color: theme.palette.text.secondary }}>
                                <Typography sx={{ p: 1 }}>Diameter of cluster</Typography>
                            </InfoButton>
                            <TextField name='min-size' type='text' inputMode='numeric' value={minSize} variant='standard' onChange={onTextFieldChange} />
                            <Typography margin='0 4px'>to</Typography>
                            <TextField name='max-size' type='text' inputMode='numeric' value={maxSize} variant='standard' onChange={onTextFieldChange} />
                            <Select name='unit' value={units} variant='standard' onChange={onSelectChange} sx={{ marginLeft: '8px' }}>
                                <MenuItem value='mi'>mi</MenuItem>
                                <MenuItem value='km'>km</MenuItem>
                            </Select>
                            <Typography marginLeft='16px'>Points in cluster</Typography>
                            <InfoButton sx={{ alignSelf: 'center', cursor: 'help', color: theme.palette.text.secondary }}>
                                <Typography sx={{ p: 1 }}>Number of points in cluster</Typography>
                            </InfoButton>
                            <TextField name='min-points' type='text' inputMode='numeric' value={minPoints} variant='standard' onChange={onTextFieldChange} />
                            <Typography margin='0 4px'>to</Typography>
                            <TextField name='max-points' type='text' inputMode='numeric' value={maxPoints} variant='standard' onChange={onTextFieldChange} />
                        </Stack>
                    </Stack>
                    <Stack direction='column' width='12em' justifyContent='center' alignItems='left' paddingLeft='4em'>
                        <Stack direction='row'>
                            <Button onClick={onSaveClick} disabled={isProcessLoading || !jobNeedsSaved} sx={{justifyContent:'left'}}>Save</Button>
                                <ErrorOutlineIcon sx={{ visibility:isProcessLoading || !jobNeedsSaved ? 'hidden' : 'visible', color:'orange'}}/>
                        </Stack>
                        <Stack direction='row'>
                            <Button onClick={onRunClick} disabled={isProcessLoading || jobNeedsSaved} sx={{justifyContent:'left'}}>Run</Button>
                            <ErrorOutlineIcon sx={{ visibility:isProcessLoading || jobNeedsSaved ? 'hidden' : 'visible', color:'red'}}/>
                        </Stack>
                        <Stack direction='row'>
                            <Button onClick={onExportClick} disabled={(activeJob?.result?.clusters?.length || 0) == 0 || isLoading
                                || activeJob?.status != JobStatus.SUCCESS
                            }
                                sx={{justifyContent:'left'}}>Export</Button>
                            <ErrorOutlineIcon sx={{ visibility:'hidden'}}/>
                        </Stack>
                    </Stack>
                </Stack>

                <Box sx={{ height: '16px' }}></Box>
                {activeJob && activeJob.result?.clusters?.length > 0 && activeJob.status == JobStatus.SUCCESS
                    ? <ResultView
                        result={activeJob?.result}
                        time={
                            activeJob?.updated
                            && activeJob.updated != activeJob?.updated
                            && DateTime.fromISO(activeJob.updated).toLocaleString(DateTime.DATETIME_SHORT)
                            || undefined
                        }
                    />
                    : <NoContentBox>
                        {
                            activeJob?.status == JobStatus.FAIL ?
                            <>
                                <SearchIcon />
                                Clustering failed with logs:<br/>
                                { activeJob?.log }
                                <br />Please run again.
                            </>
                            : !activeJob?.result?.clusters || activeJob?.result?.clusters?.length == 0
                            ? <>
                                <SearchIcon />
                                No visits match specified filters.
                                <br />Try to relax conditions.
                            </>
                            : <>
                                <WorkspacesIcon />
                                There is no clustering for this client.
                                <br />Let&apos;s create one!
                            </>
                        }
                    </NoContentBox>
                }
            </Stack>
            </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>OK</Button>
            </DialogActions>
            <Snackbar
                open={snackOpen}
                autoHideDuration={3000}
                onClose={onSnackClose}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                message='Table was copied to clipboard'
            />
        </Dialog >
    )
}

export default ClusterDialog;