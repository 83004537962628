import * as React from 'react';
import { Autocomplete, BaseTextFieldProps, Checkbox, CheckboxProps, FormControl, FormControlLabel, FormHelperText, InputLabel, Select, SelectChangeEvent, SelectProps, StandardTextFieldProps, TextField } from '@mui/material';
import { DateTimePicker, DateTimePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import enumMenuItems from '../utils/EnumMenuItems';

interface FieldProps<T> {
    value: T | null;
    onChange: (label: string, value: T | null) => void;
}

interface DateFieldOptionProps {
    error?:boolean;
    autoFillOnClick?:boolean;
}

export const TextF = ({ value, onChange, name, ...props }: Omit<StandardTextFieldProps,'onChange'> & FieldProps<string>): JSX.Element => {
    const callOnChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(name || '', event.target.value);
    }, [name, onChange]);
    return (
        <TextField {...props} name={name} label={props.label || name} value={value == null ? '' : value} variant='standard' onChange={callOnChange} multiline={props.multiline} />
    );
}

export const NumberField = ({ value, onChange, name, ...props }: BaseTextFieldProps & FieldProps<number>): JSX.Element => {
    const callOnChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        /^\d+$/.test(event.target.value) && onChange && onChange(name || '', Number.parseInt(event.target.value, 10));
    }, [name, onChange]);
    return (
        <TextField {...props} name={name} label={props.label || name} value={value} variant='standard' type='number' onChange={callOnChange} />
    );
}

export const DateField = ({ name, error, value, onChange, label, autoFillOnClick=false }: DateFieldOptionProps & FieldProps<Date> & { name: string, label?: string }): JSX.Element => {
    const callOnChange = React.useCallback((value: Date | null) => {
        onChange && onChange(name, value || null);
    }, [name, onChange]);
    return <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DateTimePicker
            label={label || name}
            inputFormat='yyyy-MM-dd hh:mm'
            value={value}
            renderInput={(params) =>
                <TextField name={name} variant='standard' {...params} error={error}
                    onClick={() => {
                        if (!value && autoFillOnClick) {
                            callOnChange(DateTime.now().toJSDate());
                        }
                    }
                }/>
            }
            onChange={callOnChange} />
    </LocalizationProvider>
}

export const DateTimeField = ({ name, value, onChange, label, ...props }: Omit<DateTimePickerProps<DateTime,DateTime>,'onChange'|'renderInput'> & FieldProps<DateTime> & { name: string, label?: string }): JSX.Element => {
    const callOnChange = React.useCallback((value: DateTime | null) => {
        onChange && onChange(name, value);
    }, [name, onChange]);
    return <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DateTimePicker
            {...props}
            label={label || name}
            inputFormat='yyyy-MM-dd hh:mm'
            value={value}
            renderInput={(params) =>
                <TextField name={name} variant='standard' {...params} />
            }
            onChange={callOnChange} />
    </LocalizationProvider>
}

export const BoolField = ({ name, value, label, onChange, ...props }: Omit<CheckboxProps,'onChange'> & FieldProps<boolean> & {label?: string}): JSX.Element => {
    const callOnChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(name || '', event.target.checked);
    }, [name, onChange]);
    return <FormControlLabel
        control={
            <Checkbox
                {...props}
                name={name}
                checked={!!value}
                onChange={callOnChange} />
        }
        label={label || name} />
}

interface EnumFieldProps<T> extends FieldProps<T> {
    options: (string|[string,string])[];
    helpText?:string;
    hiddenLabel?: boolean;
    clearLabel?: string;
}

export function EnumField<T extends string|string[]>({ name, value, options, hiddenLabel, clearLabel, onChange, helpText, ...props }: Omit<SelectProps<T/*,'standard'*/>,'onChange'|'variant'> & EnumFieldProps<T>): JSX.Element  {
    const callOnChange = React.useCallback((event: SelectChangeEvent<T>) => {
        onChange && onChange(name||'', event.target.value as T);
    }, [name, onChange]);
    return (
        <FormControl variant='standard' fullWidth hiddenLabel={hiddenLabel}>
            {hiddenLabel ? null : <InputLabel>{props.label || name}</InputLabel>}
            <Select<T/*,'standard'*/> {...props} name={name} value={value} onChange={callOnChange}>
                {enumMenuItems(options, clearLabel)}
            </Select>
            <FormHelperText>{helpText}</FormHelperText>
        </FormControl>
    );
}

interface AutocompleteEnumFieldProps extends EnumFieldProps<string[]> {
    name: string;
    label?: string;
}

export function AutocompleteEnumField({ 
    value, options, hiddenLabel, label, name, onChange 
}: AutocompleteEnumFieldProps): JSX.Element {

    const callOnChange = React.useCallback((event: React.SyntheticEvent<Element, Event>, element: string[] | null) => {
        onChange && onChange(name || '', element as string[]);
    }, [name, onChange]);
    return (
        <FormControl variant='standard' fullWidth hiddenLabel={hiddenLabel}>
            {hiddenLabel ? null : <InputLabel>{name}</InputLabel>}
            <Autocomplete
                multiple
                value={value||undefined}
                options={options.map((item) => (item instanceof Array ? item[0] : item))}
                onChange={callOnChange}
                // onInputChange={callOnChange}
                renderInput={(params) => <TextField {...params} label={label || name} variant='standard' />}
                disableCloseOnSelect
                renderTags={(values) => values.join(', ')}
            />
        </FormControl>
    );
}

export const AutocompleteField = <T extends string>({ name, value, options, hiddenLabel, onChange, ...props }
    : Omit<SelectProps<T/*,'standard'*/>,'onChange'|'variant'> & EnumFieldProps<T>): JSX.Element => {
    const callOnChange = React.useCallback((event: React.SyntheticEvent<Element, Event>, element:string|null) => {
        onChange && onChange(name||'', element as T);
    }, [name, onChange]);
    return (
        <FormControl variant='standard' fullWidth hiddenLabel={hiddenLabel}>
            <Autocomplete
                freeSolo
                value={value}
                defaultValue={value||undefined}
                options={options.map((item) => (item instanceof Array ? item[0] : item ))}
                onChange={callOnChange}
                onInputChange={callOnChange}
                renderInput={(params) => <TextField {...params} label={props.label || name} variant='standard' />}
            />
        </FormControl>
    );
};

interface AutoCloseEnumFieldProps<T> extends EnumFieldProps<T> {
    valueToClose?: string;
}

export function AutoCloseEnumField<T extends string|string[]>({ valueToClose, value, onChange, ...props }: Omit<SelectProps<T/*,'standard'*/>,'onChange'|'variant'> & AutoCloseEnumFieldProps<T>): JSX.Element  {
    const [open, setOpen] = React.useState(false);
    const onOpen = React.useCallback(() => {
        setOpen(true);
    }, []);
    const onClose = React.useCallback(() => {
        setOpen(false);
    }, []);
    const callOnChange = React.useCallback((label: string, newValue: T|null) => {
        onChange && onChange(label, newValue);
        if (valueToClose && newValue instanceof Array && newValue.includes(valueToClose) && !value?.includes(valueToClose)) {
            setOpen(false);
        }
    }, [valueToClose, value, onChange]);
    return <EnumField {...{value, open, onOpen, onClose}} {...props} onChange={callOnChange} />
}