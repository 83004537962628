import * as React from 'react';
import { useState } from 'react';
import { useLoaderData, LoaderFunctionArgs } from "react-router-dom";
import { API, ListResponse } from '../utils/Api';
import { Client, Patient } from '../models/core';
import { AuthData } from '../models/ziphy';
import AppBarPage from '../components/AppBarPage';
import { Box, TextField, Button, Select, MenuItem, List, ListItem, ListItemText, Divider, Grid, ListItemButton } from '@mui/material';

export const loader = async ({ params, request }: LoaderFunctionArgs): Promise<[string]> => {
    if (!API.token || API.token === '') {
        const authItem = window.localStorage.getItem('auth');
        const auth = authItem ? (JSON.parse(authItem) as AuthData) : null;
        API.token = auth?.accessToken?.value || '';
    }
    return [''];
}

const AdminPage = (): JSX.Element => {
    const [_key] = useLoaderData() as [string];
    const [tabIndex, setTabIndex] = useState(0);
    const [searchInput, setSearchInput] = useState('');
    const [submitInput, setSubmitInput] = useState('');
    const [patient, setPatient] = useState<Patient|undefined>();
    const [client, setClient] = useState<Client|undefined>();
    const [patientList, setPatientList] = useState<Patient[]>([]);
    const [clientList, setClientList] = useState<Client[]>([]);

    const handleTabChange = (newIndex: number) => {
        setTabIndex(newIndex);
    };

    const retrievePatient = React.useCallback(async (query:string) => {
        const queryArr = query.replaceAll('\n',';').split(';')
            .map<[string,string]>((kv) => { const newVal = kv.split('='); return [newVal[0],newVal[1]]} );
        const request = await API.getPatientsQuery(queryArr).catch((error) => console.log(error));
        const items = request as ListResponse<Patient>;
        if(items == null) {
            return;
        }
        setPatientList(items.items || []);
        setSubmitInput(JSON.stringify(items.items || {})|| '');
    }, []);

    const refreshPatients = React.useCallback(async (mode:string, client?:Client) => {
        await API.adminRefreshPatientState(mode, client?._id).catch((error) => console.log(error));
    }, []);

    return (
        <AppBarPage title='Admin Tools'>
            <Grid container spacing={2}>
                {/* Left Sidebar List */}
                <Grid item xs={2}>
                    <List>
                        <ListItem onClick={() => handleTabChange(0)}>
                            <ListItemButton selected={tabIndex == 0}>
                                <ListItemText primary="Patient" />
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                        <ListItem onClick={() => handleTabChange(1)}>
                            <ListItemButton selected={tabIndex == 1}>
                                <ListItemText primary="Client" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Grid>

                {/* Right Side Content */}
                <Grid item xs={10}>
                    {tabIndex === 0 && (
                        <Box p={2}>
                            <Box display="flex" alignItems="center" gap={2} mb={2}>
                                <TextField
                                    label="Search Input"
                                    multiline
                                    fullWidth
                                    rows={3}
                                    value={searchInput}
                                    onChange={(e) => setSearchInput(e.target.value)}
                                />
                                <Button variant="contained" color="primary" 
                                    onClick={()=>{retrievePatient(searchInput);}}>
                                        Search
                                </Button>
                            </Box>

                            {/* TextField + Button for Submit */}
                            <Box display="flex" alignItems="center" gap={2}>
                                <TextField
                                    label="Submit Input"
                                    multiline
                                    fullWidth
                                    rows={6}
                                    value={submitInput}
                                    onChange={(e) => setSubmitInput(e.target.value)}
                                />
                            </Box>


                            <Button variant="contained" color="primary" 
                                onClick={()=>{refreshPatients('lcfn');}}>
                                Fix LCFN
                            </Button>
                            <Button variant="contained" color="primary" 
                                onClick={()=>{refreshPatients('fixid');}}>
                                Fix ZCMID
                            </Button>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </AppBarPage>
    );
}

export default AdminPage;