import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, 
    Link, DialogProps, DialogTitle, TextField, Typography, 
    FormControl,
    Select,
    InputLabel,
    MenuItem} from '@mui/material';
import { Client, Patient as OutreachPatient, TrackableMeasureTypes, Visit } from '../../models/core';
import { API as ZiphyAPI } from '../../utils/ZiphyAPI';
import { useQuery } from '@tanstack/react-query';
import { Patient as ZiphyPatient, Place, DTime, ScheduleStaffPair, Office } from '../../models/ziphy';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime, IANAZone, Zone } from 'luxon';
import ZiphyPatientInput from '../book/ZiphyPatientInput';
import ZiphyPlaceInput from '../book/ZiphyPlaceInput';
import SpinnerButton from '../SpinnerButton';
import useSnackbar from '../../hooks/useSnackbar';
import AlertSnack from '../ErrorSnack';
import { dtimeToLuxon, luxonToDtime } from '../../utils/time';
import AddressDialog, { AddressDialogMode } from '../patients/2_0/schedule/AddressDialog';
import useDialog from '../../hooks/useDialog';
import GridForm, { GridFormItem } from '../GridForm';
import CalendarGrid from '../patients/2_0/schedule/CalendarGrid';
import ConfirmBookingDialog from '../patients/2_0/schedule/ConfirmBookingDialog';
import { API } from '../../utils/Api';


function useServicesQuery(practiceId: number) {
    return useQuery({
        queryKey: ['practice_services', practiceId],
        queryFn: async () => (await ZiphyAPI.getPracticeServices(practiceId)),
        select: ({ practice_services, expanded: { services: { items: serviceMap } } }) => practice_services.items.map(ps => ({ ...ps, service: serviceMap.find(it => it.key == ps.service_id)?.value })),
        staleTime:60_000
    });
}

function useOfficeQuery(practiceId: number) {
    return useQuery({
        queryKey: ['practice_office', practiceId],
        queryFn: async () => (await ZiphyAPI.getOffices({ 
            and:[
                { eq: ['is_active', true] },
                { eq: ['practice_id', practiceId] }
            ]
        })),
        select: (data) => data?.offices?.items || [],
        staleTime:120_000
    });
}

function usePracticeQuery(practiceId: number) {
    return useQuery({
        queryKey: ['practice_read', practiceId],
        queryFn: async () => (await ZiphyAPI.getPractice(practiceId)),
        select: (data) => data,
        staleTime:120_000
    });
}

function useTrackableQuery(patientId: string, clientId:string) {
    return useQuery({
        queryKey: ['patient_trackables', patientId, clientId],
        queryFn: async () => (await API.getPatientTrackables(clientId, patientId)),
        select: (data) => data?.items || [],
        staleTime:240_000
    });
}

interface Props extends DialogProps {
    open: boolean;
    outreachPatient:OutreachPatient;
    outreachClient:Client;
    outreachVisit?:Visit;
    practiceId: number;
    date: DateTime;
    onSubmit?: () => void;
    onCancel?: () => void;
}

export interface BookingWindow {
    id:number;
    time:DTime;
    localTime:DateTime;
    utcTime:DateTime;
    staff:number[];
    hasPrefered:boolean;
}

const BookDialog = ({ open, outreachPatient:patient1, outreachVisit:visit1, outreachClient,
    practiceId, date:initialDate, onCancel, ...props  }: Props): JSX.Element => {

    const [ziphyPatient, setZiphyPatient] = useState<ZiphyPatient | null>(null);
    const [place, setPlace] = useState<Place | null>(null);
    const [startDate, setStartDate] = useState<DateTime>((initialDate || DateTime.now()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
    const [endDate, setEndDate] = useState<DateTime>((initialDate || DateTime.now()).set({ day:(DateTime.now() || initialDate).day + 1, hour: 0, minute: 0, second: 0, millisecond: 0 }));
    const [serviceId, setServiceId] = useState('');
    const [outreachPatient, _setOutreachPatient] = useState<OutreachPatient>(patient1 || {} as OutreachPatient);
    const [outreachVisit, _setOutreachVisit] = useState<Visit>(visit1 || {} as Visit);
    
    const [scheduleStaff, setScheduleStaff] = useState<ScheduleStaffPair[]>();
    const [scheduleWindows, setScheduleWindows] = useState<BookingWindow[]>();
    const [selectedWindow, setSelectedWindow] = useState<BookingWindow>();
    const [providers, setProviders] = useState<ScheduleStaffPair[]>();
    const [agents, setAgents] = useState<ScheduleStaffPair[]>();
    const [providerId, setProviderId] = useState<number>();
    const [agentId, setAgentId] = useState<number>();
    const [useInsurance, setUseInsurance] = useState<boolean>(true);
    const [searchingSchedules, setSearchingSchedules] = useState(false);

    const { showSuccess: snackSuccess, showError: snackError, ...snackProps } = useSnackbar();
    const { show: showPlaceEditor, hide: hidePlaceEditor, open: isPlaceEditorOpen } = useDialog();
    const { show: showBookingError, hide: hideBookingError, open: isBookingErrorOpen } = useDialog();
    const { show: showBookingSuccess, hide: hideBookingSuccess, open: isBookingSuccesOpen } = useDialog();


    const servicesQuery = useServicesQuery(practiceId);
    const service = useMemo(() => (
        servicesQuery.data?.find(s => s.service_id + '' == serviceId)
    ), [servicesQuery, serviceId]);

    const officeQuery = useOfficeQuery(practiceId);
    const office = useMemo(() => {
        return officeQuery?.data?.find((o) => o.is_active && o.is_main);
    },[officeQuery]);

    const officeZone = useMemo(() => {
        return IANAZone.create(office?.timezone || 'UTC');
    },[office])

    const practiceQuery = usePracticeQuery(practiceId);
    const patientTrackables = useTrackableQuery(outreachClient._id, outreachPatient._id);

    const onEnumChange = useCallback((label: string, value: string | null) => {
        switch (label) {
            case 'service': setServiceId(value || ''); break;
        }
    }, []);

    const onStartDateChange = useCallback((value: string|null) => {
        if(!value) { return; }
        setStartDate(
            DateTime.fromISO(value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        );
    }, []);

    const onEndDateChange = useCallback((value: string|null) => {
        if(!value) { return; }
        setEndDate(
            DateTime.fromISO(value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        );
    }, []);

    const buildServiceSelectOptions = useMemo(() => {

        const capitalizeFirstLetter = (str: string) => 
            str.charAt(0).toUpperCase() + str.slice(1);

        const servArr = servicesQuery.data || [];
        const serviceRecord: Record<string, [string, string][]> = {};
    
        // Populate serviceRecord with grouped services
        servArr
            .filter((ps) => !ps.service?.has_symptoms)
            .forEach((s) => {
                const key = s?.service?.patient_group_type || "";
                if (!serviceRecord[key]) {
                    serviceRecord[key] = [];
                }
    
                serviceRecord[key].push([
                    s.service_id + "",
                    `${s.service?.name} (${s.duration}min, ` +
                    [
                        ...(s.service?.agent_load ? ["OCC"] : []),
                        ...(s.service?.provider_load ? ["Provider"] : []),
                    ].join("+") + ")",
                ]);
            });
    
        // Convert serviceRecord into Select options with headers
        const selectOptions = Object.entries(serviceRecord).flatMap(([header, items]) => [
            { type: "header", value:'', label: capitalizeFirstLetter(header) },
            ...items.map(([value, label]) => ({ type: "option", value:value, label })),
        ]);
    
        return selectOptions;
    }, [servicesQuery]);


    const patientNotesString = React.useMemo(() => {
        function copyConditionData(contentName:string, content:{name:string, value:string}[], skipValue:boolean) {
            let copyData:string = contentName;
            content?.forEach((it) => { copyData = copyData + `\n${it.name}${skipValue ? '' : ':' + it.value}`; });
            return copyData;
        }

        let copyString = [
            `Name: ${outreachPatient?.lastName}, ${outreachPatient?.firstName}`,
            ...(outreachPatient?.dob ? [`DOB: ${outreachPatient.dob}`] : []),
            ...(outreachPatient?.gender ? [`SAB: ${outreachPatient.gender.toString()}`] : []),
            ...(outreachPatient?.language ? [`Language: ${outreachPatient.language}`] : []),
            ...(outreachPatient?.phone ? [`Phone: ${outreachPatient.phone}`] : [])
        ].join('\n');
        
        copyString += '\n';
        const conditionsList:Record<string, {name:string, value:string}[]> = {};
        patientTrackables?.data?.forEach((it) => {
            it?.trackable?.forEach((item) => {
                if(!conditionsList[item.type]) {
                    conditionsList[item.type] = [];
                }
                conditionsList[item.type].push({ name:item.name || '', value:item.value || '' })
            })
        });
        Object.entries(conditionsList).forEach((e) => { copyString += copyConditionData(e[0]+'s',e[1], e[0] == TrackableMeasureTypes.MEASURE) + '\n' });
        return copyString;
    
    }, [patientTrackables, outreachPatient]);



    const onConfirmDialogUpdateState = useCallback((provider:number, agent:number, insurance:boolean, _notes:string) => {
        setProviderId(provider);
        setAgentId(agent);
        setUseInsurance(insurance);
    },[])

    const requestStaffSchedule = useCallback(async (patient:ZiphyPatient, zone:Zone, begin:DTime, end:DTime, 
        officeId:number, serviceId:number, placeId:number, patientNumber = 1) => {
            try {
                const request = await ZiphyAPI.getSchedulableStaff(begin, end, officeId, true, placeId, serviceId, patientNumber);
                if(request) {
                    const patPref = (patient.preferred_provider_ids || []);
                    const mapping = (request?.windows?.items?.items || [])
                        .filter(ft => ft?.key != undefined)
                        .map((its, idx) => {
                            const time = its.key || {} as DTime;
                            const val = its.value||[];
                            const utcDateTime = DateTime.utc(time.year, time.month, time.day, time.hour, 0);
                            const t = {
                                id:idx,
                                time:time,
                                localTime:utcDateTime.setZone(zone),
                                utcTime:utcDateTime,
                                staff:val,
                                hasPrefered:!!patPref.find(pr => val.includes(pr))
                            }
                            return t;
                    });
                    setScheduleWindows(mapping);
                    setScheduleStaff(request?.staff?.items?.items || []);
                }
            }catch(error) {
                console.error(error);
                setScheduleWindows([]);
                setScheduleStaff([]);
            }
    },[]);

    const onBookClicked = useCallback(async (patientId:number, practiceId:number, officeId:number, placeId:number, serviceId:number,
        agentId:number, providerId:number, notes:string, time:DTime, insurance:boolean
    ) => {
        try {
            const request = await ZiphyAPI.bookAppointment(
                {
                    practice_id:practiceId,
                    office_id:officeId,
                    place_id:placeId,
                    service_id:serviceId,
                    agent_role_id:agentId,
                    provider_role_id:providerId,
                    description: notes,
                    answers: { 0: { 1: 1, 2: 1, 7: 1, 8: 1, 9: 1, 10: 1 } },
                    start: time,
                    patients: [{ id: patientId, use_insurance: insurance }],
                    symptom_ids: [],
                    optimistic: true
                }
            ) || null;
            if(request && request.id) {
                if(outreachVisit) {
                    const visitUpdate = { ...outreachVisit, ...{appointmentId:request.id}};
                    await API.updateVisit(visitUpdate).catch((error) => console.log(error));
                }


            }
            return;
        }catch(error) {
            console.error(error)
        }
    }, [outreachVisit]);

    return (
        <Dialog {...props} open={open} onClose={onCancel}
            PaperProps={props?.PaperProps || { sx: { height:'80%', maxHeight:'90vh', minHeight:'70%', 
                width:'480px', minWidth:'480px', maxWidth: '80%' } }}>
            <DialogTitle>Book Appointment</DialogTitle>
            <DialogContent>

                <Typography variant='h5'>Patient</Typography>
                <Box sx={{ width: '1px', height: '0.5em' }} />
                <GridForm>
                <GridFormItem xs={6}>
                    <ZiphyPatientInput
                        patientId={outreachPatient.zcmId ? Number.parseInt(outreachPatient.zcmId) : undefined}
                        value={ziphyPatient}
                        practiceId={practiceId}
                        initialInput={`${outreachPatient.firstName} ${outreachPatient.lastName}`}
                        onChange={setZiphyPatient}/>

                </GridFormItem>
                <GridFormItem xs={6}>
                    <ZiphyPlaceInput
                        value={place}
                        placeId={ziphyPatient?.place_id ? ziphyPatient?.place_id : undefined}
                        practiceId={practiceId}
                        initialInput={`${outreachPatient.address}, ${outreachPatient.city}, ${outreachPatient.zip}`}
                        onChange={setPlace}
                    />
                    <Typography variant='body2' sx={{ visibility: place == null ? 'visible' : 'hidden' }}>
                        No Place found?
                        You may <Link
                            component='button'
                            variant='body2'
                            onClick={showPlaceEditor}
                        >create</Link> one
                    </Typography>
                </GridFormItem>
                </GridForm>

                {
                    isPlaceEditorOpen && <AddressDialog mode={AddressDialogMode.CreatePlace} open={isPlaceEditorOpen}
                        practiceId={practiceId} patient={outreachPatient} onChange={setPlace} onClose={hidePlaceEditor}/>
                }
                <Box sx={{ width: '1px', height: '1em' }} />

                <Typography variant='h5'>Booking</Typography>
                <Box sx={{ width: '1px', height: '0.5em' }} />

                <GridForm>
                    <GridFormItem xs={6}>
                    <FormControl variant='standard' fullWidth >
                        <InputLabel>Service</InputLabel>
                        <Select name={'service'} value={serviceId} onChange={(e) => onEnumChange('service',e.target.value)}>
                        {
                            buildServiceSelectOptions.map((option, index) =>
                            option.type === "header" ? (
                                <MenuItem key={index} disabled><strong>{option.label}</strong></MenuItem>
                            ) : (
                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            ))
                        }
                        </Select>
                    </FormControl>
                    </GridFormItem>
                    <GridFormItem xs={2}>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                            value={startDate}
                            onChange={onStartDateChange}
                            label='Start Date'
                            disableMaskedInput
                            maxDate={endDate.toISODate()||''}
                            renderInput={(params) =>
                                <TextField name='date' variant='standard' {...params} />
                            }
                        />
                    </LocalizationProvider>
                    </GridFormItem>
                    <GridFormItem xs={2}>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                            value={endDate}
                            onChange={onEndDateChange}
                            label='End Date'
                            disableMaskedInput
                            minDate={startDate.toISODate()||''}
                            renderInput={(params) =>
                                <TextField name='date' variant='standard' {...params} />
                            }
                        />
                    </LocalizationProvider>
                    </GridFormItem>

                    <GridFormItem xs={2}>
                    <SpinnerButton fullWidth sx={{textAlign:'center'}}
                        disabled={  !office?.id || !officeZone || !startDate || !endDate || startDate >= endDate || !place?.id || !service || !practiceId }
                        onClick={async () => {
                            setSearchingSchedules(true);
                            if(officeZone && ziphyPatient && office?.id && startDate && endDate && place?.id && service && practiceId) {
                                try {
                                await requestStaffSchedule(
                                    ziphyPatient,
                                    officeZone,
                                    luxonToDtime(startDate),
                                    {   year: endDate.year, month: endDate.month, day: endDate.day + 1,
                                        hour: 0, minute: 0, second: 0, utcoffset: 0, _type: 'datetime' },
                                        office.id, Number.parseInt(serviceId), place?.id, 1);
                                }catch(error) {
                                    console.error(error);
                                }
                            }
                            setSearchingSchedules(false);
                        }
                        } showSpinner={searchingSchedules}>Search Slots</SpinnerButton>
                    </GridFormItem>
                </GridForm>

                <Box sx={{ width: '1px', height: '0.5em' }} />
                <CalendarGrid timeZone={officeZone} start={startDate} end={endDate} windows={scheduleWindows || []}
                    noWindowsText={ searchingSchedules ? 'Searching For Available Times' :
                        service ? 'Select a Service and press Search Slots to view available times.' : 'Press Search Slots to search for available times.'
                    }
                    interval={service?.duration || 60} onSelect={(slot) => {

                        const sll = scheduleStaff || [];
                        const staff:ScheduleStaffPair[] = [];
                        (slot?.staff || []).forEach((st) => {
                            const sm = sll.find(ck => ck.key == st);
                            if(sm) {
                                staff.push(sm);
                            }
                        });
                        const providerList = staff.filter(f => f?.value?.role == 'provider') || [];
                        const agentList = staff.filter(f => f?.value?.role == 'agent') || [];
                        const preferedIDs = ziphyPatient?.preferred_provider_ids || [];
                        const preferedProvider = slot.hasPrefered ? providerList.find((pl) => pl.key && preferedIDs.includes(pl.key)) : undefined;
                        setProviders(providerList);
                        setAgents(agentList);
                        setProviderId(preferedProvider?.key || (providerList.length > 0 ? providerList[0]?.key : undefined));
                        setAgentId(agentList.length > 0 ? agentList[0]?.key : undefined);
                        setSelectedWindow(slot);
                    }}/>

            </DialogContent>
            <DialogActions>
                <Button type='button' onClick={onCancel}>Cancel</Button>
            </DialogActions>

            { selectedWindow && office && service && <ConfirmBookingDialog 
                open={!!selectedWindow}
                place={place}
                useInsurance={useInsurance}
                duration={service?.duration || 0}
                bookingWindow={selectedWindow}
                office={office}
                serviceName={service?.service?.name}
                patient={outreachPatient}
                notes={patientNotesString}
                practiceName={practiceQuery.data?.name || ''}
                preferedIds={ziphyPatient?.preferred_provider_ids||[]}
                providerId={providerId}
                agentId={agentId}
                providers={providers}
                agents={agents}
                onUpdateState={onConfirmDialogUpdateState}
                onCancel={()=> {setSelectedWindow(undefined)}}
                onBookClick={
                    async (pid:number, aid:number, insurance:boolean, notes:string)=>{
                        await onBookClicked(Number.parseInt(outreachPatient.zcmId || ''), practiceId, practiceId, 
                            place?.id || 0, Number.parseInt(serviceId), aid || 0, pid || 0, 
                            notes, selectedWindow.time, insurance);
                        
                        setSelectedWindow(undefined);
                    }
                }
            />}

            <AlertSnack {...snackProps} />
        </Dialog>
    );
}

export default BookDialog;
