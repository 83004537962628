import { Gender, MeasureComplianceStatus, PatientStatus, VisitStatus } from "../models/core"

export const MeasureComplianceStatuses = [
    MeasureComplianceStatus.IGNORED,
    MeasureComplianceStatus.COMPLIANT,
    MeasureComplianceStatus.NON_COMPLIANT
];

export const VisitStatuses = [
    VisitStatus.NEW,
    VisitStatus.IN_PROGRESS,
    VisitStatus.INACTIVE,
    VisitStatus.COMPLETED,
    VisitStatus.REFUSED,
    VisitStatus.SCHEDULED,
    VisitStatus.UTR,
];

export const PatientStatuses = [
    PatientStatus.ACTIVE,
    PatientStatus.INACTIVE,
    PatientStatus.EXCLUDED,
    PatientStatus.DONOTCONTACT,
];

export const Genders = [
    Gender.MALE,
    Gender.FEMALE,
    Gender.UNKNOWN
];

export const LoBs = [
    'TotalAdvantage',
    'HealthAdvantage',
    "MAP",
    "MLTC",
];

export const MeasurementNames = [
    'Care for Older Adults (COA): Functional Status Assessment',
    'Care for Older Adults (COA): Medication Review',
    'Care for Older Adults(COA): Pain Assessment',
    'Controlling High Blood Pressure (CBP)',
    'Hemoglobin A1c (HBD)',
    'Kidney Health Evaluation:(eGFR, Urine Creatinine, Urine Albumin)',
    'Transitions of Care (TRC): Medication Reconciliation Post-Discharge',
    'Hearing Exam',
    'Dental Exam',
    'Influenza Vaccine',
    'Pneumococcal Vaccine'
];

export const PhoneOutcomes = [
    'No Phone Number',
    'Out of order',
    'Rings no VM',
    'Left Message',
    'Message not left',
    'Patient hung up',
    'Scheduled',
    'Patient will call back',
    'ZCM to call the patient back',
    'Other'
];


export const TextOutcomes = [
    'Sent SMS',
    'No Cell #',
    'Message Failed',
    'No Response',
    'Scheduled',
    'Other'
];

export const EmailOutcomes = [
    'Sent Email',
    'No Email',
    'Message Failed',
    'No Response',
    'Scheduled',
    'Other'
];

export const MailOutcomes = [
    'Sent Mail',
    'No Address',
    'Mail Returned',
    'No Response',
    'Scheduled',
    'Other'
];

export const AppointmentOutcomes = [
    'Still in process of attempting to contact the member',
    'Booking in Progress',
    'Scheduled',
    'Completed',
    'Canceled',
];


export const MemberInfoUpdates = [
    'New phone number',
    'New Address',
    'New PCP',
    'New Insurance',
    'Other'
];

export const RefusalReasons = [
    'Already had services (Refused)',
    'Program not verified by PCP (Refused)',
    'Program not verified by insurance (Refused)',
    'Patient does not want a home visit (Refused)',
    'Patient refused appointment at the door (Refused)',
    'Patient hung up (Refused)',
    'Phone not in service (UTR)',
    'Phone not provided (UTR)',
    'Left messages with no response (UTR)',
    '3 attempts to call numbers provided (UTR)',
    'Unable to contact by any method (UTR)',
    'Wrong Number (UTR)'
];

export const OutcomeReasons = [
    'Patient is no longer available (Canceled)',
    'Patient is hospitalized (Canceled)',
    'Patient not feeling well (Canceled)',
    'UTR patient at appointment time (Canceled)',
    'Patient is a no-show (Canceled)',
    'Other (Canceled)',
];

export const UnknownLanguage = 'Other';
export const Languages = [
    'English', 'Spanish', 'Indian (one of)', 'Chinese', 'Ukrainian', 'Russian', UnknownLanguage
]

export const ScheduledFailedReasons = [
    'No-Show',
    'Reschedule',
    'Cancel',
    'Refused'
]

export const PhoneTypeNames = [
    'Primary Phone',
    'Secondary Phone',
    'Alt Phone',
    'TLO Phone',
    'Tevix Phone',
    'Other'
]

export const VisitReasons = ['AWV','CAF','GAP','DOH','Transition of Care','Follow Up','Labs','Wound Care']