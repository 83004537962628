import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Assuming you're using React Router

interface SourceContextType {
    sourcePath: string | undefined;
    setSourcePath: (path: string | undefined) => void;
}

const SourceContext = createContext<SourceContextType | undefined>(undefined);

interface SourceProviderProps {
    children: React.ReactNode;
    source?:string;
    capturePathOn?: string;
}

export const SourceProvider: React.FC<SourceProviderProps> = ({ children, source, capturePathOn }) => {
    const [sourcePath, setSourcePath] = useState<string|undefined>(source);
    const location = useLocation();

    useEffect(() => {
        if (capturePathOn && (location.pathname === capturePathOn)) {
            setSourcePath(location.pathname + location.search);
        }
    }, [location, capturePathOn]);

    const value: SourceContextType = {
        sourcePath,
        setSourcePath,
    };

    return <SourceContext.Provider value={value}>{children}</SourceContext.Provider>;
};

export const useSource = () => {
    const context = useContext(SourceContext);
    if (!context) {
        throw new Error('useSource must be used within a SourceProvider');
    }
    return context;
};