import * as React from 'react';
import { useState } from 'react';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import {  Box, Typography, Stack, Button, Link,} from '@mui/material';
import { API } from '../utils/Api';
import { Filter, ReadPatientExpandedResult, API as ZiphyAPI } from '../utils/ZiphyAPI'
import { Client, DocumentInfo, Gender, Patient, PatientStatus, VisitStatus } from '../models/core';
import useDialog from '../hooks/useDialog';
import { analytics } from '../utils/analytics/zipAnalytics';
import { Address, AuthData } from '../models/ziphy';
import { useLoading } from '../hooks/useLoading';
import UCMEditor from '../components/UCMEditor';
import OutreachPatientSearchDialog from '../components/patients/OutreachPatientSearchDialog';
import SimpleDialog from '../components/dialogs/SimpleDialog';
import { Patient as ZiphyPatient } from "../models/ziphy";
import { useInactivity } from '../hooks/useInactivity';

export type EmbededLoaderData = [string|null, string|null, string|null, string|null ];

export const loader = async ({ params, request }: LoaderFunctionArgs): Promise<EmbededLoaderData> => {

    const searchParams = new URL(request.url).searchParams;
    const practiceId = params.practiceId || searchParams.get('practiceId');
    const ziphyPatientId = params.patientId || searchParams.get('patientId') || '';
    const style = params.interface || searchParams.get('style') || '';
    //const route = params.route || searchParams.get('route') || '';
    const source = params.source || searchParams.get('source') || '';

    if(!API.token || API.token == '') {
        const authItem = window.localStorage.getItem('auth');
        const auth = authItem ? (JSON.parse(authItem) as AuthData) : null;
        API.token = auth?.accessToken?.value || '';
    }

    return [practiceId, ziphyPatientId, style, source];
}

const EmbededPage = (): JSX.Element => {

    const loading = useLoading();

    const [practiceId, ziphyPatientId, style, source] = useLoaderData() as EmbededLoaderData;
    const activityMonitor = useInactivity();

    const [activeClient, setActiveClient] = useState<Client>();
    const [activePatient, setActivePatient] = useState<Patient>();
    const [patientList, setPatientList] = useState<Patient[]>();
    const [linkingPatient, setLinkingPatient] = useState<Patient>();
    const [error, setError] = useState<string|string[]|JSX.Element|JSX.Element[]|null|undefined>();
    const [patientAddress, setPatientAddress] = useState<Address>();
    const [zcmPatient, setZCMPatient] = useState<ZiphyPatient>();
    const { show:showComDiag, hide:hideComDiag, open:comDiagIsOpen } = useDialog();

    type LinkState = 'Loading'|'Select'|'Error'|'UCM'|'Closed';
    const [linkState, setLinkState] = useState<LinkState>('Loading');


    const logError = React.useCallback((errorName:string, error:string, data: { [key: string]: string | number | boolean; }) => {
        console.log(error);
        const messageData = {...(data||{}), ...{ error:error, source:source||'stl', style:style||'stl'}};
        analytics.error(errorName, messageData, true);
    },[source, style]);


    const retrieveOutreachClient = React.useCallback(async (zpID:string|null) => {
        if(zpID == null) {
            logError('embeded_error', 'Null or Missing Practice ID', {});
            return null;
        }

        const clients = await API.getClientsByPractice(zpID).catch((error) => {
            logError('api_error', `Error in client: ${error as string}`, {});
        });

        const client = clients?.items?.at(0);
        if(client == null) {
            logError('embeded_error', `Null or Missing Client with ID: ${zpID}`, {});
            return null;
        }
        return client;
    },[logError])

    const retrieveOutreachPatient = React.useCallback(async (zcmId:string, practiceId:string, clientId:string) => {

        const ziphyIdNum = Number.parseInt(zcmId);

        // Get the ZCM Patient
        const ziphyRequest = await ZiphyAPI.getPatientExpanded(ziphyIdNum).catch((error) => {
            logError('api_error', error as string, {});
        });

        const zcmPatient:ZiphyPatient|null|undefined = ziphyRequest?.patient;
        if(!zcmPatient) {
            logError('embeded_error', `Failed to retrieve zcm patient with ID: ${zcmId}`, {});
            return null;
        }

        //  Check if the patient is already linked
        const request = await API.getPatients(clientId, [['zcmId', zcmId.toString()]], 10, 0).catch((error) => {
            logError('api_error', `Error in client: ${error as string}`, {});
        });

        const outreachPatient = request?.items?.at(0);
        if(outreachPatient) {
            return { ziphyPatient:zcmPatient, outreachList:[outreachPatient], address:null };
        }

        const zdob = zcmPatient?.dob;
        const dob = `${zdob?.year}-${zdob?.month?.toString().padStart(2,'0')}-${zdob?.day?.toString().padStart(2,'0')}`;
        const searchRequest = await API.getPatients(clientId,
            [
                ['rgx.firstName', zcmPatient?.first_name?.toUpperCase() ||''],
                ['rgx.lastName', zcmPatient?.last_name?.toUpperCase() ||''],
                ['dob', dob]
            ],
            25, 0
        );

        let address:Address|undefined = undefined;
        const place = (ziphyRequest as ReadPatientExpandedResult)?.expanded?.places?.items?.at(0)?.value;
        if(place) {
            address = {
                country:place.country,
                state:place.state,
                postalCode:place.zip,
                city:place.city,
                line:[`${place.building} ${place.street}`, ...(place.apartment ? [place.apartment] : [])]
            } as Address;
        } else {
            const addrReq = await ZiphyAPI.getAddresses(ziphyIdNum).catch(error => console.error(error));
            address = addrReq?.addresses?.items?.find(adr => adr.use == 'home' && adr.type == 'physical');
        }
        setPatientAddress(address || undefined);

        const patientList = (searchRequest?.items || []); //.filter(p => p._id != outreachPatient?._id)) || [];
        return { ziphyPatient:zcmPatient, outreachList:patientList, address:address };
    },[logError])

    const createNewPatient = React.useCallback(async (newPatient:Patient|undefined|null) => {
        if(!newPatient) {
            const message = [
                <Typography key={'c1'} variant="h6" align="center"> {"The required patient information is missing or not provided. Go back to ZiphyAdmin and try again."} </Typography>,
                <Typography key={'c2'} variant="h6" align="center">
                    { 'Contact '}
                    <Link href="mailto:help@ziphycare.com" underline="hover">
                        help@ziphycare.com
                    </Link>
                    {' if you continue to receive this message.'}
                </Typography>
            ]

            setError(message);
            return null;
        }

        const info:DocumentInfo = { lastEditedDate:'', lastEditedBy:'', lastEditedById:-1, lastEditReason:'' }
        const assign = { assignedBy:'', assignedDate:'', assignedTo:'None'};
        const altPatient = { ...newPatient, ...{ assignment:assign, info:info } }

        const { acknowledged, insertedId } = await API.createPatient(altPatient);
        if(!acknowledged) {
            const message = [
                <Typography key={'c1'} variant='h6' align='center'> {'We ran into a problem when trying to create the patient, try again in a few minutes.'} </Typography>,
                <Typography key={'c2'} variant="h6" align="center">
                    { 'Contact '}
                    <Link href="mailto:help@ziphycare.com" underline="hover">
                        help@ziphycare.com
                    </Link>
                    {' if you continue to receive this message.'}
                </Typography>
            ]

            setError(message);
            analytics.error('patient_create_error', { clientId: newPatient.clientId, }, true);
            return null;
        }
        analytics.track('patient_create', { clientId: newPatient.clientId, patientId: insertedId, refId: newPatient.refId }, true)

        const visit = {
            status: VisitStatus.NEW, language: 'English', scheduled1: undefined, scheduled2: undefined,
            scheduled3: undefined, scheduled4: undefined, scheduledReason1: undefined, scheduledReason2: undefined,
            scheduledReason3: undefined, scheduledReason4: undefined, comments: undefined, clientNotes: undefined, clientDataEntry: undefined,
            appointmentId: undefined, customFields: undefined, numCalls: 0, numEmails: 0, numSMS: 0, numMails: 0,
            numContacts: 0, numReschedules : 0, refusalReason: undefined, phoneDate: undefined, emailDate: undefined, utrDate: undefined,
            scheduledDate: undefined, phoneOutcome: undefined, emailOutcome: undefined, appointmentOutcome: undefined, notes: undefined,
            memberInfoUpdate: undefined, info:info, lastContactDate: 'unknown', patientId: insertedId, clientId: newPatient.clientId, 
            refId:`visit-${insertedId}-0`
        }
        await API.createVisit(visit);

        return { ...altPatient, ...{ _id:insertedId } };
    },[])

    const createPatient = React.useCallback((zcm:ZiphyPatient, client:Client, address:Address):Patient => {
        const dobC = zcm?.dob;
        const dob = `${dobC?.year}-${dobC?.month?.toString().padStart(2,'0')}-${dobC?.day?.toString().padStart(2,'0')}`;
        return {
            status:PatientStatus.ACTIVE,
            firstName:zcm?.first_name?.toUpperCase(),
            lastName:zcm?.last_name?.toUpperCase(),
            dob:dob,
            email:zcm?.email,
            gender:zcm?.gender == 'Male' ? Gender.MALE : zcm?.gender == 'Female' ? Gender.FEMALE  : Gender.UNKNOWN,
            phone:zcm?.phone,
            refId:`ZCMID-${zcm?.id}`,
            zcmId:zcm?.id?.toString(),
            clientId:client?._id || '',
            address: (address?.line && address?.line?.length >= 1) ? address?.line?.at(0) : undefined,
            address2: (address?.line && address?.line?.length >= 2) ? address?.line?.at(1) : undefined,
            city: address?.city,
            state: address?.state,
            zip: address?.postalCode
        } as Patient;
    },[])

    const loadUserOutreachData = React.useCallback(async () => {
        loading.showLoading();

        if(!practiceId) {
            const message = [
                <Typography key={'c1'} variant='h6' align='center'> {'The Practice ID is missing or not provided. Go back to ZiphyAdmin and try again. Go back to ZiphyAdmin and try again.'} </Typography>,
                <Typography key={'c2'} variant="h6" align="center">
                    { 'Contact '}
                    <Link href="mailto:help@ziphycare.com" underline="hover">
                        help@ziphycare.com
                    </Link>
                    {' if you continue to receive this message.'}
                </Typography>
            ];
            setError(message);
        } else if(!ziphyPatientId) {
            const message = [
                <Typography key={'c1'} variant='h6' align='center'> {'The Patient ID is missing or not provided. Go back to ZiphyAdmin and try again. Go back to ZiphyAdmin and try again.'} </Typography>,
                <Typography key={'c2'} variant="h6" align="center">
                    { 'Contact '}
                    <Link href="mailto:help@ziphycare.com" underline="hover">
                        help@ziphycare.com
                    </Link>
                    {' if you continue to receive this message.'}
                </Typography>
            ];
            setError(message);
        } else {
            const client = await retrieveOutreachClient(practiceId);
            setActiveClient(client||undefined);
            if(client) {
                const rop = await retrieveOutreachPatient(ziphyPatientId, practiceId || '', client._id);

                if(!rop || !rop?.ziphyPatient) {
                    const message = [
                        <Typography key={'c1'} variant='h6' align='center'> {`We failed to retrieve patient id: ${ziphyPatientId} for practice: ${practiceId}. Go back to ZiphyAdmin and try again.`} </Typography>,
                        <Typography key={'c2'} variant="h6" align="center">
                            { 'Contact '}
                            <Link href="mailto:help@ziphycare.com" underline="hover">
                                help@ziphycare.com
                            </Link>
                            {' if you continue to receive this message.'}
                        </Typography>
                    ];
                    setError(message);
                } else {
                    setZCMPatient(rop.ziphyPatient);
                    setPatientList(rop.outreachList);

                    const patient = rop.outreachList?.length == 1 ? rop.outreachList[0] : undefined;
                    setActivePatient(patient);

                    if(patient) {
                        if(!patient.zcmId || patient.zcmId != '') {
                            await updatePatientZCMId(ziphyPatientId, patient, (error, updatedPatient) => {
                                if(error) {
                                    setError(error);
                                } else {
                                    setActivePatient(updatedPatient)
                                    setLinkState('UCM');
                                }
                            });
                        } else {
                            setLinkState('UCM');
                        }
                    } else if(rop.outreachList?.length > 0) {
                        setLinkState('Select');
                    } else {
                        const newPatient = createPatient(rop.ziphyPatient, client, rop.address || {} as Address);
                        const awt = await createNewPatient(newPatient);
                        setActivePatient(awt as Patient);
                        const message = [
                            <Typography key={'c1'} variant='h6' align='center'> {`We ran into a problem when trying to create patient: ${ziphyPatientId}, try again in a few minutes.`} </Typography>,
                            <Typography key={'c2'} variant="h6" align="center">
                                { 'Contact '}
                                <Link href="mailto:help@ziphycare.com" underline="hover">
                                    help@ziphycare.com
                                </Link>
                                {' if you continue to receive this message.'}
                            </Typography>
                        ]
                        awt ? setLinkState('UCM') : setError(message);
                    }
                }
            } else {
                const message = [
                    <Typography key={'c1'} variant='h6' align='center'> {`We ran into a problem trying to find: ${practiceId}, Go back to ZiphyAdmin and try again.`} </Typography>,
                    <Typography key={'c2'} variant="h6" align="center">
                        { 'Contact '}
                        <Link href="mailto:help@ziphycare.com" underline="hover">
                            help@ziphycare.com
                        </Link>
                        {' if you continue to receive this message.'}
                    </Typography>
                ]
                setError(message);
            }
        }

        loading.hideLoading();
    },[retrieveOutreachClient, retrieveOutreachPatient, createNewPatient, createPatient, ziphyPatientId, practiceId])
    

    React.useEffect(() => {
        loadUserOutreachData();
        activityMonitor.activate(true, '/notice?type=embeddedTimeout');
    },[])

    const confirmLinkPatientBox = React.useCallback((zcmId:string, linkingPatient:Patient, onCancel:()=>void, onConfirm:()=>void) => {
        return (
            <SimpleDialog open={true} title={<b>{'Attention'}</b>}
                body={
                    <>
                        <Typography variant="body1" align="center"> {`You are about to link Patient ID ${zcmId}`}</Typography>
                        <Typography variant="body1" align="center"> {`with Outreach Patient: ${linkingPatient?.lastName}, ${linkingPatient?.firstName}.`} </Typography>
                        <Typography variant="body1" align="center"> {'This cannot be undone.'} </Typography>
                        <br/>
                        <Typography variant="body1" align="center"> {'Do you want to continue?'} </Typography>
                    </>
                }
                buttons={[
                    {text:'Cancel', onClick:onCancel },
                    {text:'Link Patient', onClick:onConfirm }
                ]}
        />);
    },[]);

    const showScreenDialog = React.useCallback((errorTitle:string, errorText:string|string[]|JSX.Element|JSX.Element[], buttonText?:string, callback?:()=>void) => {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <Box width={'60%'} height={'60%'} display="flex" justifyContent="center" alignItems="center"
                    border={1} borderRadius={2} boxShadow={1} p={2}>
                    <Stack direction={'column'}>
                        <Typography paddingBottom={'0.5em'} variant="h4" align="center"> {errorTitle} </Typography>
                        {
                            Array.isArray(errorText) 
                                ? errorText.map((e, index) => <Typography key={`txt-${index}`} variant="h6" align="center">{e}</Typography>) 
                                : (typeof errorText === 'string' || React.isValidElement(errorText)) 
                                  ? <Typography variant="h6" align="center">{errorText}</Typography> 
                                  : null
                        }
                        <Box height='1em'/>
                        { callback ? <Button variant='contained' onClick={callback}>{buttonText}</Button> : <></> }
                    </Stack>
                </Box>
            </Box>
        );
    },[]);

    const updatePatientZCMId = React.useCallback(async (zcmId:string, patient:Patient, onComplete:(error:string|null, patient:Patient) => void) => {
        try{
            const zipPat = {...patient, ...{zcmId:zcmId} };
            const ret = await API.updatePatient(zipPat)
            if(ret) {
                onComplete(null, zipPat);
            }
        }catch(error){
            onComplete(error as string || 'Unknwon Error', patient);
        }
    }, []);

    const isClosableWindow = React.useMemo(() => {
        return style == 'tab' || style == 'window';
    }, [style]);

    const closeEvent = React.useCallback(() => {
        setLinkState('Closed');
        if(isClosableWindow) {
            window.close();
        }
    }, [isClosableWindow])

    return <>
        {   error && showScreenDialog('Error', error, isClosableWindow ? 'Close Window' : undefined, isClosableWindow ? closeEvent : undefined)   }
        {
            linkState == 'Select' && <OutreachPatientSearchDialog open={true} fullscreen={true} client={activeClient || {} as Client}
                searchDetails={
                    {
                        firstName:zcmPatient?.first_name?.toUpperCase() || undefined,
                        lastName:zcmPatient?.last_name?.toUpperCase() || undefined,
                        dob:`${zcmPatient?.dob?.year}-${zcmPatient?.dob?.month?.toString().padStart(2,'0')}-${zcmPatient?.dob?.day?.toString().padStart(2,'0')}`
                    }
                }
                patients={patientList||[]}
                onClose={closeEvent}
                onSubmit={
                    (p) => {
                        setLinkingPatient(p);
                        showComDiag();
                    }
                }
                onCreatePatient={
                    () => {
                        zcmPatient && setActivePatient(createPatient(zcmPatient, activeClient || {} as Client, patientAddress || {} as Address))
                        setLinkState('UCM');
                    }
                }
            />
        }
        {
            linkState == 'UCM' && <>
                <UCMEditor page={'patient'} open={true} onClose={closeEvent} limitedPrompt={true}
                    onSave={() => {console.log('close')}} patient={activePatient} backdropClose={false}
                    client={activeClient || {} as Client} plans={[]} patientId={activePatient?._id || ''}
                    dialogPaperProps={{sx: { height:'100%', maxHeight:'100%', width: '100%', maxWidth: '100%' }}}
                    fullScreen
                />
            </>
        }
        {
            comDiagIsOpen && confirmLinkPatientBox(ziphyPatientId || '', linkingPatient || {} as Patient, hideComDiag, async () => {
                loading.showLoading();
                await updatePatientZCMId(ziphyPatientId || '', linkingPatient || {} as Patient, (error, updatedPatient) => {
                    if(error) {
                        setError(error);
                    } else {
                        setActivePatient(updatedPatient);
                        setLinkState('UCM');
                    }
                    hideComDiag();
                })
                loading.hideLoading();
            })
        }
    </>
}

export default EmbededPage;