import React, { useState } from 'react';
import { Client, Visit, Patient, ScheduleJob, } from '../../../../models/core';
import BookDialog from '../../../schedule/BookDialog';
import { DateTime } from 'luxon';
import ZiphyPatientSearchDialog from '../../ZiphyPatientSearchDialog';
import RequireZcmIdDialog from './RequireZcmIdDialog';

export interface BookPatientSegmentProps {
    open: boolean;
    patient: Patient;
    visit?:Visit;
    client: Client;
    onClose?: () => void;
    onPatientUpdated?:(zcmId:string, patient:Patient) => void;
}

const BookPatientSegment = ({
    open,
    client,
    patient,
    visit,
    onClose,
    onPatientUpdated
}: BookPatientSegmentProps): JSX.Element => {

    //type SegmentState = 'Loading'|'Booking'|'NoZCMError'|'FindZCM';
    const [state, setState] = useState('Loading');
    const [outreachPatient, setOutreachPatient] = useState<Patient>(patient);

    React.useMemo(() => {
        if(!open) {
            return 'Loading';
        }
        setState(outreachPatient.zcmId && outreachPatient.zcmId != '' ? 'Booking' : 'NoZCMError');
    }, [open, outreachPatient]);


    return <>
        {
            state == 'NoZCMError' && <RequireZcmIdDialog open={state == 'NoZCMError'} 
                onClose={() => {onClose && onClose() }} onLink={() => setState('FindZCM')} />
        }
        {
            state == 'FindZCM' && <ZiphyPatientSearchDialog open={state == 'FindZCM'} client={client} patient={outreachPatient}
                onClose={()=>{
                    onClose && onClose();
                    setState('Loading');
                }}
                onUpdate={(newZCMID, updatedPatient) => {
                    setOutreachPatient(updatedPatient)
                    onPatientUpdated && onPatientUpdated(newZCMID, updatedPatient);
                    setState('Loading');
                }}
                onCreatePatient={()=>{setState('Loading');}}
            />
        }
        {
            state == 'Booking' && 
                <BookDialog open={state == 'Booking'} practiceId={client.practiceId}
                    PaperProps={{sx:{width:'70%', height:'70%', minWidth:'500px', minHeight:'70%', maxWidth:'80%', maxHeight:'80%' }}}
                    outreachPatient={outreachPatient}
                    outreachClient={client}
                    outreachVisit={visit||{} as Visit}
                    date={DateTime.now()}
                    onCancel={
                        ()=>{
                            onClose && onClose();
                            setState('Loading');
                        }
                    }
                />
        }
    </>
}

export default BookPatientSegment;