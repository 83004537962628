import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Patient } from '../../models/ziphy';
import { Filter, API as ZiphyAPI } from '../../utils/ZiphyAPI';
import { useQuery } from '@tanstack/react-query';
import FetchAutocomplete from '../schedule/FetchAutocomplete';
import { AutocompleteRenderInputParams, TextField } from '@mui/material';


async function getZiphyPatients(practiceId: number, input: string, patientId?:number): Promise<Patient[]> {
    if (input.length > 0) {
        const words = input.toLowerCase().trim().split(' ').filter(word => word.length >= 3);
        
        const filter:Filter = (patientId && patientId != 0)
            ?  { eq: ['id', patientId]}
            : {
                or: words.flatMap<Filter>(word => [
                    { imatch: ['first_name', word] },
                    { imatch: ['last_name', word] },
                    ...(word.includes('@') ? [{ imatch: ['email', word] } as Filter] : [])
                ])
            };
        return (await ZiphyAPI.getPatients({
            and: [
                { eq: ['is_hidden', false] },
                { contains: ["practice_ids", [practiceId]] },
                filter
            ]
        })).patients.items;
    }
    return [];

}

interface ZiphyPatientInputProps {
    patientId?: number;
    practiceId: number;
    initialInput: string;
    value: Patient | null;
    onChange?: (value: Patient | null) => void;
}

const ZiphyPatientInput = ({ patientId, practiceId, initialInput, value, onChange }: ZiphyPatientInputProps): JSX.Element => {
    const [patientInput, setPatientInput] = useState('');
    const firstFetch = useRef(true);

    const patientsQuery = useQuery({
        queryKey: ['patients', practiceId, patientId, patientInput, initialInput],
        queryFn: async () =>
            await getZiphyPatients(practiceId, patientInput+' '+initialInput, patientId)
    });
    const getPatientLabel = useCallback((patient: Patient) => (
        `${patient.first_name} ${patient.last_name}`
    ), []);

    const renderOption = useCallback((props: React.HTMLAttributes<HTMLLIElement>, patient: Patient) => (
        <li {...props}>
            {patient.first_name} {patient.last_name} 
            ({patient.dob?.year ? (new Date().getFullYear() - patient.dob.year) + ' y.o.' : ''} 
            {patient.gender})
        </li>
    ), []);
    const optEqual = useCallback((x: Patient, y: Patient) => x.id == y.id, []);

    useEffect(() => {
        if (value == null && patientInput=='' && patientsQuery.data && patientsQuery.data.length > 0 && firstFetch.current) {
            onChange && onChange(patientsQuery.data[0]);
            firstFetch.current = false;
        }
    }, [value, patientsQuery, onChange, firstFetch, patientInput])

    const renderInput = useCallback((params: AutocompleteRenderInputParams) => (
        <TextField {...params} label='Patient' fullWidth variant='standard' />
    ), []);

    return (
        <FetchAutocomplete
            options={patientsQuery.data || []}
            value={value}
            onChange={onChange}
            onInputChange={setPatientInput}
            getOptionLabel={getPatientLabel}
            renderOption={renderOption}
            isOptionEqualToValue={optEqual}
            renderInput={renderInput}
        />
    );
}
export default ZiphyPatientInput;