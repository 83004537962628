import * as React from 'react';
import { useCallback, useState } from 'react';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import 'react-phone-number-input/style.css'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { analytics } from '../../utils/analytics/zipAnalytics'
import { useSource } from '../../hooks/useSource';


const EnterCodeFragment = (): JSX.Element => {

    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const auth = useAuth();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [submitting, setSubmiting] = useState(false);
    const lastSource = useSource();

    const onCodeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if(code != event.target.value) {
            setCode(event.target.value);
            setSubmiting(false);
        }
    }, [setSubmiting, code]);

    const onSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!code) {
            setError('Field is required');
            return;
        }
        if(submitting) {
            setError('Code has already been submited.');
            analytics.error('auth_error', {message:'Duplicate Core Request'}, true);
            return;
        }

        setSubmiting(true);

        let authData = undefined;
        try {
            authData = await (auth && auth.login(state.type, state.value, code));
        } catch (e) {
            setError(((e as Error)?.cause as {message?:string}).message || e + '');
            analytics.error('auth_error', { message: e + '', user:state.value }, true);
        }
        if
         (authData) {
            if ((authData.roles || []).filter(r => ['admin', 'practice_admin', 'dispatcher'].includes(r.role || '')).length > 0) {
                const origin = lastSource.sourcePath ? lastSource.sourcePath : (state && state.from) ? (state.from.pathname + state.from.search) : '/';
                console.log(origin)
                navigate(origin);
                //navigate(0); // reload page to render <RoutesRoot> with new auth
            } else {
                auth?.logout();
                navigate('/auth/error');
            }
        }
        setSubmiting(false);
    }, [auth, code, navigate, state, setSubmiting, submitting]);

    if (!state || !state.type) {
        return <Navigate to="/auth/login" replace />;
    }

    return (
        <form onSubmit={onSubmit}>
            <Stack spacing={1} sx={{}}>
                <Typography variant='h3' sx={{ mb: 2 }}>Code</Typography>
                <TextField placeholder='code' value={code} error={error != ''} helperText={error} onChange={onCodeChange} autoFocus />
                <Button variant='contained' type='submit' disabled={submitting}>Login</Button>
                <Typography variant='body1' sx={{ textAlign: 'center', pt: 1 }}>
                    <Link to='/auth/login' state={state}>BACK</Link>
                </Typography>
            </Stack>
        </form>
    )
}

export default EnterCodeFragment;

