import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle,
    Button, Typography, Divider, TextField, Checkbox } from '@mui/material';
import GridForm, { GridFormItem } from '../../../GridForm';
import { EnumField } from '../../../fields';
import { Office, Place, ScheduleStaffPair } from '../../../../models/ziphy';
import { Patient as OutreachPatient } from '../../../../models/core';
import SpinnerButton from '../../../SpinnerButton';
import { BookingWindow } from '../../../schedule/BookDialog';
import { DateTime } from 'luxon';

const Months = [
    'January', 'February', 'March', 'April', 'May', 'June', 
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

interface ConfirmBookingDialogProps {
    open: boolean;
    onCancel: () => void;
    onUpdateState: (providerId:number, agentId:number, insurance:boolean, notes:string) => void;
    onBookClick: (providerId:number, agentId:number, insurance:boolean, notes:string) => void;
    useInsurance:boolean;
    duration:number;
    bookingWindow: BookingWindow;
    place: Place | null;
    office: Office;
    serviceName: string | undefined;
    patient: OutreachPatient | undefined;
    preferedIds?:number[];
    notes: string|undefined;
    practiceName: string | undefined;
    providerId?: number;
    agentId?: number;
    providers?: ScheduleStaffPair[];
    agents?: ScheduleStaffPair[];
}

const ConfirmBookingDialog: React.FC<ConfirmBookingDialogProps> = ({
    open, onCancel, onBookClick, place, serviceName, patient, onUpdateState, duration, useInsurance,
    practiceName, providerId, agentId, providers, agents, bookingWindow, notes, office, preferedIds
}) => {
    
    const [isBooking, setIsBooking] = React.useState<boolean>(false);

    function getTimeString(date:DateTime, offsetMinutes:number) {
        const dt = DateTime.now().set({year:date.year, month:date.month, day:date.day, hour:date.hour, minute:date.minute + offsetMinutes});
        return dt.toFormat("hh:mm a");
    }

    const handleDialogClose = React.useCallback((_event:object, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason && (reason === "backdropClick" || reason == 'escapeKeyDown')) {
            return;
        }
        onCancel();
    },[onCancel]);

    return (
        <Dialog open={open} onClose={handleDialogClose}>
            <DialogTitle>
                <Typography variant='h5'>
                    <b>
                    { `${Months[bookingWindow.localTime.month - 1]} ${bookingWindow.localTime.day.toString().padStart(2,'0')} ` }
                    </b>
                    { `${bookingWindow.localTime.year}` }
                </Typography>
                <Typography variant='subtitle1'>{ getTimeString(bookingWindow.localTime, 0) + ' - ' + getTimeString(bookingWindow.localTime, duration) + ` (${duration}min)`}</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant='h5'>Appointment Details</Typography>
                <GridForm>
                    <GridFormItem xs={2}>Address</GridFormItem>
                    <GridFormItem xs={10} sx={{ justifyContent: 'right' }}>
                        {`${place?.building} ${place?.street}, ${place?.city}, ${place?.state}, ${place?.zip}`}
                    </GridFormItem>
                    <GridFormItem xs={12}><Divider /></GridFormItem>

                    <GridFormItem xs={2}>Service</GridFormItem>
                    <GridFormItem xs={10} sx={{ justifyContent: 'right' }}>{serviceName}</GridFormItem>
                    <GridFormItem xs={12}><Divider /></GridFormItem>

                    <GridFormItem xs={2}>Patient</GridFormItem>
                    <GridFormItem xs={10} sx={{ justifyContent: 'right' }}>
                        {`${patient?.firstName} ${patient?.lastName}`}
                    </GridFormItem>
                    <GridFormItem xs={12}><Divider /></GridFormItem>

                    <GridFormItem xs={2}>Use Insurance</GridFormItem>
                    <GridFormItem xs={10} sx={{ justifyContent: 'right' }}>
                    <Checkbox
                        checked={useInsurance}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                            onUpdateState(providerId || 0, agentId || 0, e.target.checked, notes || '')}
                    />
                    </GridFormItem>
                    <GridFormItem xs={12}><Divider /></GridFormItem>

                    <GridFormItem xs={2}>Practice</GridFormItem>
                    <GridFormItem xs={10} sx={{ justifyContent: 'right' }}>{practiceName}</GridFormItem>
                    <GridFormItem xs={12}><Divider /></GridFormItem>

                    <GridFormItem xs={2}>Office</GridFormItem>
                    <GridFormItem xs={10} sx={{ justifyContent: 'right' }}>{office.name}</GridFormItem>
                    <GridFormItem xs={12}><Divider /></GridFormItem>

                    <GridFormItem xs={2}>Provider</GridFormItem>
                    <GridFormItem xs={10} sx={{ justifyContent: 'right' }}>
                        <EnumField name='Provider' value={providerId?.toString() || ''}
                            options={(providers || []).map(s => [
                                s?.key?.toString() || '',
                                !bookingWindow.hasPrefered ? s?.value?.name || '' 
                                    : (preferedIds || []).includes(s?.key || 0)
                                        ? `['Prefered'] ${s?.value?.name || ''}`
                                            : s?.value?.name || ''
                            ])}
                            onChange={(_l,v) => { onUpdateState(Number.parseInt(v || ''), agentId || 0, useInsurance, notes||''); }}
                        />
                    </GridFormItem>
                    <GridFormItem xs={12}><Divider /></GridFormItem>

                    <GridFormItem xs={2}>OCC</GridFormItem>
                    <GridFormItem xs={10} sx={{ justifyContent: 'right' }}>
                        <EnumField name='OCC' value={agentId?.toString() || ''}
                            options={(agents || []).map(s => [s?.key?.toString() || '', s?.value?.name || ''])}
                            onChange={(_l,v) => { onUpdateState(providerId || 0, Number.parseInt(v || ''), useInsurance, notes||''); }}
                        />
                    </GridFormItem>
                    <GridFormItem xs={12}><Divider /></GridFormItem>

                    <GridFormItem xs={12}>
                        <TextField sx={{ minHeight: '50px', width: '100%' }} multiline name='patient-data'
                            value={notes || ''}
                            onChange={(e) => onUpdateState(providerId || 0, agentId || 0, useInsurance, e.target.value || '')}
                        />
                    </GridFormItem>
                </GridForm>
            </DialogContent>
            <DialogActions>
                <Button disabled={isBooking} type='button' onClick={onCancel}>Cancel</Button>
                <SpinnerButton onClick={
                    async () => { 
                        setIsBooking(true);
                        onBookClick(providerId || 0, agentId ||0, useInsurance, notes || '')
                        setIsBooking(false);
                    }
                } showSpinner={isBooking}>Book</SpinnerButton>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmBookingDialog;
