import React, { createContext, useContext, useEffect, useRef, useCallback, ReactNode, useState } from "react";
import { useLocation } from "react-router-dom";

interface InactivityContextType {
  touch: () => void;
  activate: (active?:boolean, redirect?:string) => void;
  isActive?: boolean;
}

const InactivityContext = createContext<InactivityContextType | null>(null);

interface InactivityProviderProps {
  children: ReactNode;
  onTimeout: (redirect?:string) => void;
  timeoutMs?: number;
  isActive?: boolean;
}

export function InactivityProvider({ isActive:active1 = true, children, onTimeout, timeoutMs = 30 * 60 * 1000 }: InactivityProviderProps) {
  const location = useLocation();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isActive, setIsActive] = useState<boolean|undefined>(active1);
  const [redirect, setRedirect] = useState<string>();

  React.useEffect(() => {
    document.body.addEventListener('click', resetTimer);
    return () => { document.body.removeEventListener('click', resetTimer); }
  });


  const activate = useCallback((active?:boolean, redirect?:string) => {
    setIsActive(active);
    setRedirect(redirect);
  }, [])

  const resetTimer = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(()=>{onTimeout(redirect)}, timeoutMs);
  }, [onTimeout, timeoutMs, redirect]);

  useEffect(() => {
    resetTimer();
  }, [location, resetTimer]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <InactivityContext.Provider value={{ touch: resetTimer, isActive:isActive, activate:activate }}>
      {children}
    </InactivityContext.Provider>
  );
}

export function useInactivity() {
  const context = useContext(InactivityContext);
  if (!context) {
    throw new Error("useInactivity must be used within an InactivityProvider");
  }
  return context;
}
