import * as React from 'react';
import { Container, CssBaseline, Paper } from '@mui/material';
import 'react-phone-number-input/style.css'
import { Outlet, useLocation } from 'react-router-dom';
import { SourceProvider } from '../hooks/useSource';


const LoginPage = (): JSX.Element => {
    const location = useLocation();
    return (<React.Fragment>
        <SourceProvider source={location?.state?.from?.pathname + location?.state?.from?.search}>
        <CssBaseline />
        <Container sx={{ maxWidth: '10000px !important', height: '100vh', background: '#eee', display: 'flex' }}>
            <Paper sx={{ width: '400px', height: '330px', margin: 'auto', padding: 4 }}>
                <Outlet />
            </Paper>
        </Container>
        </SourceProvider>
    </React.Fragment>)
};

export default LoginPage;