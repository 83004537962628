import * as React from 'react';
import { Box, Button, Container, CssBaseline, Paper, TextField } from '@mui/material';
import 'react-phone-number-input/style.css'
import GridForm, { GridFormItem } from '../../components/GridForm';


const TestEmbeded = (): JSX.Element => {

    const [accessToken, setAccessToken] = React.useState<string>();
    const [practiceId, setPracticeId] = React.useState<string>('59');
    const [patientId, setPatientId] = React.useState<string>();
    const [renderIFrame, setRenderIFrame] = React.useState<boolean>(false);

    const targetURL = (style?:string) => {
        const params = new URLSearchParams();

        if (accessToken) params.set("accessToken", accessToken);
        if (practiceId) params.set("practiceId", practiceId);
        if (patientId) params.set("patientId", patientId);
        params.set('route', 'patient');
        params.set('source', 'admin');
        params.set('style', style ? style : 'window');
        return (window.location.hostname == 'localhost' ? "http://localhost:3000/embeded?" : "https://hedis-dev.ziphy.com/embeded?") + params.toString();   
    }

    const RenderIFrame = () => {
        const params = new URLSearchParams();

        if (accessToken) params.set("accessToken", accessToken);
        if (practiceId) params.set("practiceId", practiceId);
        if (patientId) params.set("patientId", patientId);

        return (
          <iframe
            src= { targetURL('stl') }
            width="100%"
            height="100%"
            title="Outreach"
          />
        );
      };


    return <React.Fragment>
        <CssBaseline />
        <Container sx={{ maxWidth: '10000px !important', height: '100vh', background: '#eee', display: 'flex' }}>
            <Paper sx={{ width: '100%', height: '100%', margin: 'auto', padding: 4 }}>
                <Box sx={{ width: '80%', margin: 'auto', padding: 4 }}>
                    <GridForm>
                        <GridFormItem xs={12}>
                            <TextField name='auth' label='Access Token' value={accessToken}
                                onChange={(ev) => setAccessToken(ev.target.value)} fullWidth
                                variant='standard' helperText='*Remember to Log Out. App will attempt to use local cached credentials.'
                                disabled={renderIFrame}/>
                        </GridFormItem>
                        <GridFormItem xs={6}>
                            <TextField name='practiceId' label='Practice ID' value={practiceId}
                                onChange={(ev) => setPracticeId(ev.target.value)} fullWidth
                                variant='standard' disabled={renderIFrame}/>
                        </GridFormItem>
                        <GridFormItem xs={6}>
                            <TextField name='patientId' label='Patient ID' value={patientId}
                                onChange={(ev) => setPatientId(ev.target.value)} fullWidth
                                variant='standard' disabled={renderIFrame}/>
                        </GridFormItem>
                        <GridFormItem xs={6}>
                            <Button fullWidth sx={{justifyContent:'center'}} onClick={()=>{setRenderIFrame(!renderIFrame)}}>
                                    {(renderIFrame ? 'Stop' : 'Start') + ' Embeded App'}
                                </Button>
                        </GridFormItem>
                        <GridFormItem xs={6}>
                            <Button fullWidth sx={{justifyContent:'center'}} onClick={()=>{ window.open(targetURL(), "_blank")}}>
                                Open New Window
                            </Button>
                        </GridFormItem>
                    </GridForm>
                </Box>
                <Box sx={{ width: '60%', height: '60%', margin: 'auto', padding: 4 }}>
                    { renderIFrame ? <RenderIFrame/> : <></> }
                </Box>
            </Paper>
        </Container>
    </React.Fragment>
}

export default TestEmbeded;