import React, { lazy, Suspense, useState, useCallback, useMemo } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Select,
    Box,
    InputLabel,
    FormControl,
    MenuItem,
} from "@mui/material";
import GridForm, { GridFormItem } from "../../../GridForm";
import { Patient, Provider } from "../../../../models/core";
import { Place } from '../../../../models/ziphy'
import RequireZcmIdDialog from "./RequireZcmIdDialog";
import { API } from "../../../../utils/ZiphyAPI";
import { EnumField } from "../../../fields";
import { TIME_ZONES } from "../../../../utils/timezone";

const PlacesAutocomplete = lazy(() => import('./PlacesAutocomplete'));

export enum AddressDialogMode { Address, CreatePlace }

interface AddressDialogProps {
    open: boolean;
    mode?: AddressDialogMode;
    patient?: Patient;
    practiceId?: number;
    provider?: Provider;
    onClose: () => void;
    onChange?: (place: Place) => void;
}

const AddressDialog: React.FC<AddressDialogProps> = ({
    open,
    mode = AddressDialogMode.Address,
    patient,
    practiceId,
    onClose,
    onChange,
}) => {
    const [address, setAddress] = useState<Place>({timezone:'America/New_York'} as Place);

    const states = [
        { name: "Alabama", code: "AL" }, { name: "Alaska", code: "AK" }, { name: "Arizona", code: "AZ" },
        { name: "Arkansas", code: "AR" }, { name: "California", code: "CA" }, { name: "Colorado", code: "CO" },
        { name: "Connecticut", code: "CT" }, { name: "Delaware", code: "DE" }, { name: "Florida", code: "FL" },
        { name: "Georgia", code: "GA" }, { name: "Hawaii", code: "HI" }, { name: "Idaho", code: "ID" },
        { name: "Illinois", code: "IL" }, { name: "Indiana", code: "IN" }, { name: "Iowa", code: "IA" },
        { name: "Kansas", code: "KS" }, { name: "Kentucky", code: "KY" }, { name: "Louisiana", code: "LA" },
        { name: "Maine", code: "ME" }, { name: "Maryland", code: "MD" }, { name: "Massachusetts", code: "MA" },
        { name: "Michigan", code: "MI" }, { name: "Minnesota", code: "MN" }, { name: "Mississippi", code: "MS" },
        { name: "Missouri", code: "MO" }, { name: "Montana", code: "MT" }, { name: "Nebraska", code: "NE" },
        { name: "Nevada", code: "NV" }, { name: "New Hampshire", code: "NH" }, { name: "New Jersey", code: "NJ" },
        { name: "New Mexico", code: "NM" }, { name: "New York", code: "NY" }, { name: "North Carolina", code: "NC" },
        { name: "North Dakota", code: "ND" }, { name: "Ohio", code: "OH" }, { name: "Oklahoma", code: "OK" },
        { name: "Oregon", code: "OR" }, { name: "Pennsylvania", code: "PA" }, { name: "Rhode Island", code: "RI" },
        { name: "South Carolina", code: "SC" }, { name: "South Dakota", code: "SD" }, { name: "Tennessee", code: "TN" },
        { name: "Texas", code: "TX" }, { name: "Utah", code: "UT" }, { name: "Vermont", code: "VT" },
        { name: "Virginia", code: "VA" }, { name: "Washington", code: "WA" }, { name: "West Virginia", code: "WV" },
        { name: "Wisconsin", code: "WI" }, { name: "Wyoming", code: "WY" }
    ];


    const onEnumChange = useCallback((_label: string, value: string | null) => {
        setAddress((prev) => ({...prev, ...{timezone:value||''}} ));
    }, []);

    const requireZCMIdDialog = useCallback(() => {
        const isOpen = mode == AddressDialogMode.CreatePlace && (patient?.zcmId == undefined || patient?.zcmId == '');
        return isOpen && <RequireZcmIdDialog open={isOpen} onClose={onClose} />;
    }, [patient, mode]);


    const createPlace = useCallback(async (address: Place) => {
        if (mode != AddressDialogMode.CreatePlace || !patient || !practiceId) {
            return;
        }

        const request = await API.createPlace(address, practiceId).catch((err) => { console.error(err) });
        const place = request as Place;
        if (!place) {
            // Show Dialog
            return;
        }

        onChange && onChange(place);

        onClose && onClose();
    }, [patient, practiceId]);

    const onSelectAddress = useCallback((place: Place) => {
        setAddress(place);
    }, []);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Enter Address</DialogTitle>
            <DialogContent>
                <Box sx={{ height: '1em', display: 'block' }} />
                <Suspense fallback={<Box sx={{ height: '4em', display: 'block' }}>Loading...</Box>}>
                    <PlacesAutocomplete open={open} onSelect={onSelectAddress} />
                </Suspense>
                <Box sx={{ height: '3em', display: 'block' }} />
                <GridForm>

                    <GridFormItem xs={4}>
                        <TextField fullWidth label="Building" margin="dense" value={address.building} />
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField fullWidth label="Apartment" margin="dense" value={address.apartment} />
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField fullWidth label="Floor" margin="dense" value={address.floor} />
                    </GridFormItem>

                    <GridFormItem xs={12}>
                        <TextField fullWidth label="Street" margin="dense" value={address.street} />
                    </GridFormItem>
                    <GridFormItem xs={8}>
                        <TextField fullWidth label="City" margin="dense" value={address.city} />
                    </GridFormItem>
                    <GridFormItem xs={2}>
                        <FormControl fullWidth margin="dense">
                            <InputLabel>State</InputLabel>
                            <Select value={address.state || ""} onChange={(e) => setAddress({ ...address, state: e.target.value })}>
                                {states.map((state) => (<MenuItem key={state.code} value={state.code}>{state.code}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </GridFormItem>
                    <GridFormItem xs={2}>
                        <TextField fullWidth label="Zip" margin="dense" value={address.zip} />
                    </GridFormItem>

                    <GridFormItem xs={12}>
                        <TextField fullWidth label="Country" margin="dense" value={address.country} />
                    </GridFormItem>

                    <GridFormItem xs={6}>
                        <TextField fullWidth label="Latitude" margin="dense" value={address?.coords?.[0]} />
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <TextField fullWidth label="Longitude" margin="dense" value={address?.coords?.[1]} />
                    </GridFormItem>
                    <GridFormItem xs={12}>
                        <EnumField
                            name='timezone' label='Time Zone' value={address?.timezone}
                            required onChange={onEnumChange}
                            options={TIME_ZONES}
                        />
                    </GridFormItem>
                </GridForm>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button disabled={!address || !address.street || !address.state} onClick={(e) => console.log(e) /*createPlace(address)*/} color="primary">
                    Save
                </Button>
            </DialogActions>
            {requireZCMIdDialog()}
        </Dialog>
    );
};

export default AddressDialog;
