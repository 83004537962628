import React from "react";
import SimpleDialog from "../../../dialogs/SimpleDialog";
import { Patient } from "../../../../models/ziphy";

interface RequireZcmIdDialogProps {
  open: boolean;
  patient?: Patient,
  onClose: () => void;
  onLink?: (patient?:Patient) => void;
}

const RequireZcmIdDialog: React.FC<RequireZcmIdDialogProps> = ({
    open,
    patient,
    onLink,
    onClose,
}) => {
    return open ? <SimpleDialog open={open} title={onLink ? 'Warning' : 'Error'} 
        body={
            `This outreach patient has not been linked with a Ziphy Medical Patient.
            ${(onLink ? 'Do you want to link the patient now?' : 'Please link before proceeding.')}`
        }
        buttons={[
            {text:'Close', onClick:onClose},
            ...(onLink ?  [{text:'Link Now', onClick:()=>{onLink(patient);}}] : [])
        ]}
    /> : <></>
}

export default RequireZcmIdDialog;