import * as React from 'react';
import { useCallback, useState } from 'react';
import { Button, Link, Stack, TextField, Typography } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import PhoneInputWithCountrySelect, { Value } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { analytics } from '../../utils/analytics/zipAnalytics';

const PhoneInput = React.forwardRef(function PhoneInput({ ...props }, ref) {
    return (
        <TextField
            {...props}
            name='phone'
            inputRef={ref} />
    );
})


const RequestCodeFragment = (): JSX.Element => {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState<Value>();
    const [useEmail, setUseEmail] = useState(true);
    const [error, setError] = useState('');
    const auth = useAuth();
    const navigate = useNavigate();
    const { state } = useLocation();

    const onEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }, []);

    const onSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (useEmail && !email || !useEmail && !phone) {
            setError('Field is required');
            return
        }
        let codeSent = undefined;
        try {
            codeSent = auth && (
                await auth.requestCode(
                    useEmail ? 'email' : 'phone',
                    useEmail ? email : (phone || ''))
            ) || null;
        } catch (e) {
            console.error(e);
            analytics.error('code_request_error', {'type': (useEmail ? 'email' : 'phone'), 'message': (e + '') }, true);
            setError(e + '');
        }
        if (codeSent) {
            analytics.track('code_attempt', {'type': (useEmail ? 'email' : 'phone') }, true);
            navigate(
                '../code',
                {
                    state: {
                        ...state,
                        type: useEmail ? 'email' : 'phone',
                        value: useEmail ? email : phone
                    }
                }
            )
        }
        else {
            // TODO handle email/phone error
        }
    }, [auth, email, navigate, phone, state, useEmail]);

    const onLinkClick = useCallback(() => {
        setUseEmail(!useEmail);
    }, [useEmail]);

    return (
        <form onSubmit={onSubmit}>
            <Stack spacing={1} sx={{}}>
                <Typography variant='h3' sx={{ mb: 2 }}>Login</Typography>
                <Typography variant='body1'>
                    Use existing <Link href='https://ziphy.com/' target='_blank'>ZiphyCare</Link> account.
                </Typography>
                {useEmail
                    ? <TextField name='email' type='email' placeholder='email' value={email} error={error != ''} helperText={error} onChange={onEmailChange} autoFocus/>
                    : <PhoneInputWithCountrySelect
                        value={phone}
                        onChange={setPhone}
                        defaultCountry='US'
                        inputComponent={PhoneInput} 
                        numberInputProps={{ error: error != '', helperText: error, autoFocus: true }}
                    />
                }
                <Button variant='contained' type='submit'>Login with {useEmail ? 'email' : 'phone'}</Button>
                <Typography variant='body1' sx={{ textAlign: 'center', pt: 2 }}>
                    Or continue with <Link href='#' onClick={onLinkClick}>{useEmail ? 'phone' : 'email'}</Link>.
                </Typography>
            </Stack>
        </form>
    )
}

export default RequestCodeFragment;
