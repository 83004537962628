import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import { List, ListItemButton, Tooltip, IconButton, ListItemSecondaryAction } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';
import { SchedulableSlot } from '../models/core';
import { DateTime } from 'luxon';
import { analytics } from '../utils/analytics/zipAnalytics';
import EventIcon from '@mui/icons-material/Event';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

interface SchedulableTimeItemProps {
    timeSlot: SchedulableSlot;
    onBook?:(time:SchedulableSlot|null|undefined) => void;
}

export const SchedulableTimeItem = ({ timeSlot, onBook, ...props }: SchedulableTimeItemProps): JSX.Element => {
    const onBookThis = React.useCallback(() => {
        onBook && onBook(timeSlot);
    },[timeSlot, onBook]);

    const onBookOther = React.useCallback(() => {
        onBook && onBook(null);
    },[timeSlot, onBook]);

    return (
        <ListItem
            {...props}
            >
            <ListItemButton disableRipple>
                <ListItemText
                    primary={<Typography component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                       Time: { timeSlot.time && DateTime.fromISO(timeSlot.time).toLocaleString(DateTime.DATETIME_SHORT) }
                    </Typography>}
                    secondary={
                    <span>
                        <b>Duration:</b> {timeSlot.duration} minutes
                        <br/>
                        <b>Agent:</b> {timeSlot.agentName} 
                        <br/><b>Provider:</b> {timeSlot.providerName}
                        <br/>
                        <br/>Assigned By: {timeSlot.info.lastEditedBy} &nbsp; On: {timeSlot.info.lastEditedDate}
                    </span>} />
                    {
                    <ListItemSecondaryAction>
                       <Tooltip id="tt-book-this" title="Book This Time"><IconButton onClick={onBookThis}><EventAvailableIcon/></IconButton></Tooltip>
                        <Tooltip id="tt-book-other" title="Book Other Time"><IconButton onClick={onBookOther}><EventIcon/></IconButton></Tooltip>
                    </ListItemSecondaryAction>
                    }
            </ListItemButton>
        </ListItem>
    );
};

interface SchedulableTimeListProps {
    items?: SchedulableSlot[];
    onBook?:(time:SchedulableSlot|null|undefined) => void;
}

const SchedulableTimeList = ({ items, onBook, ...props }: SchedulableTimeListProps): JSX.Element => {
    return (
        <List>
            {
                items?.map((it, i) => {return <SchedulableTimeItem key={it.patientId + i} timeSlot={it} onBook={onBook}/> }) 
                || 'No Suggested Times Available'
            }
        </List>);
};

export default SchedulableTimeList;
