import * as React from 'react';
import { useState } from 'react';
import { List, ListItemText,
    Box, Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Divider,
    ListItem,
    ListItemButton,
    ListItemSecondaryAction,
    IconButton,} from '@mui/material';
import { Client, Patient } from '../../models/core';
import { DateTime } from 'luxon';
import { GridFormItem } from '../GridForm';
import { CheckBoxSharp } from '@mui/icons-material';


interface PatientListItemProps {
    patient: Patient;
    isSelected: boolean;
    onClick?: (patient: Patient) => void;
}

const PatientItem = ({ patient, isSelected, onClick, ...props }: PatientListItemProps): JSX.Element => {
    return (
        <ListItem {...props} >
            <ListItemButton disableRipple onClick={() => { onClick && onClick(patient)}} selected={isSelected}>
                <ListItemText
                    primary={<Typography component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                        {patient.lastName}, {patient.firstName} ({
                            patient.dob && DateTime.fromISO(patient.dob).toLocaleString(DateTime.DATE_SHORT) || 'No DoB'})
                    </Typography>}
                    secondary={
                    <span>
                        Patient Status: {patient?.status || 'unknown'}
                        <br/>ID (Client, Insurtance or Other): {patient.refId || 'unknown'}
                        <br/>Medicare: {patient.medicareId || ''}
                        <br/>Medicaid: {patient.medicaidId || ''}
                    </span>} />
                <ListItemSecondaryAction>
                    { isSelected ? <IconButton><CheckBoxSharp/></IconButton> : <></> }
                </ListItemSecondaryAction>
            </ListItemButton>
        </ListItem>
    );
};

interface PatientSearchViewProps {
    open: boolean;
    client: Client;
    patients:Patient[];
    searchDetails?: Partial<Patient>;
    fullscreen?:boolean;
    onSubmit:(patient:Patient) => void;
    onClose:() => void;
    onCreatePatient?:() => void;
}

const OutreachPatientSearchDialog = ({open, patients, searchDetails, fullscreen, onSubmit, onClose, onCreatePatient}: PatientSearchViewProps): JSX.Element => {

    const [firstName, setFirstName] = useState<string>(searchDetails?.firstName || '');
    const [lastName, setLastname] = useState<string>(searchDetails?.lastName || '');
    const [dob, setDob] = useState<string>(searchDetails?.dob || '');
    const [selectedPatient, setSelectedPatient] = useState<Patient|undefined>();

    const onSelectPatient = React.useCallback((patient:Patient|undefined) => {
        setSelectedPatient(patient);
    },[])

    const patientListComponant = React.useMemo(() => {
        return <List>
            {
                (patients || []) .map((m, i) => {
                    const isSelected = selectedPatient?._id != undefined && selectedPatient._id == m._id;
                    return <PatientItem  key={'pmid-'+i} patient={m} 
                        isSelected={selectedPatient?._id != undefined && selectedPatient._id == m._id}
                        onClick={() => onSelectPatient(isSelected ? undefined : m) }/>
                })
            }
        </List>
    }, [patients, onSelectPatient, selectedPatient]);

    return (
        <Dialog
            open={open}
            fullScreen={fullscreen}
            PaperProps={ fullscreen ? {} : { sx: { height:'90%', maxHeight:'90%', width: '70%', maxWidth: '70%' } }}
        >
            <DialogTitle>Patient Filter</DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ width: '25%', minWidth:'150px', borderRight: '1px solid #ccc', padding: '4px', paddingRight:'15px', paddingBottom:'8px' }}>
                    <GridFormItem xs={12}>
                        <GridFormItem xs={12}>
                            <TextField name='firstName' label='First Name' value={firstName} aria-readonly={true}
                                onChange={(ev) => setFirstName(ev.target.value)} fullWidth
                                variant='standard' />
                        </GridFormItem>
                        <Divider/>
                        <GridFormItem xs={12}>
                            <TextField name='lastName' label='Last Name' value={lastName} aria-readonly={true}
                                onChange={(ev) => setLastname(ev.target.value)} fullWidth
                                variant='standard' />
                        </GridFormItem>
                        <Divider/>
                        <GridFormItem xs={12}>
                            <TextField name='dob' label='Date of Birth' value={dob} aria-readonly={true}
                                onChange={(ev) => setDob(ev.target.value)} fullWidth
                                variant='standard' helperText='Format: YYYY-MM-DD' />
                        </GridFormItem>
                        <Divider/>
                        <GridFormItem xs={12}><Box sx={{ height: '1em' }} /></GridFormItem>
                    </GridFormItem>
                </Box>
                <Box sx={{ width: '75%', padding: '16px' }}>
                    {
                        (patients && patients.length > 0) ? patientListComponant :
                        <Box display="flex" justifyContent="center" alignItems="center" height='80%'>
                            <Box width={'60%'} height={'60%'} display="flex" justifyContent="center" alignItems="center"
                                border={1} borderRadius={1} boxShadow={1} p={2}>
                                <Typography variant="h6" align="center">
                                    {'No Patients Currently available.'}
                                    <br/>
                                    {'Search and select a patient to proceed.'}
                                </Typography>
                            </Box>
                        </Box>
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
                <Button onClick={() => onSubmit(selectedPatient || {} as Patient) } disabled={(selectedPatient == null)}>Select Patient</Button>
                { onCreatePatient && <Button onClick={() => onCreatePatient() }>New Patient</Button> }
            </DialogActions>
        </Dialog>
    );
}

export default OutreachPatientSearchDialog;
