import React, { useCallback, useMemo } from "react";
import { Grid, Paper, Typography, Box } from "@mui/material";
import { BookingWindow } from "../../../schedule/BookDialog";
import { DateTime, Zone } from "luxon";

const startHour = 7;
const endHour = 24;

// Generate time slots dynamically
const generateTimeSlots = (interval: number) => {
    const slots = [];
    for (let hour = startHour; hour < endHour; hour++) {
        for (let minutes = 0; minutes < 60; minutes += interval) {
            slots.push({ hour, minutes });
        }
    }
    return slots;
};

export interface CalendarGridProps {
    timeZone:Zone;
    dates?: string[];
    interval?: number;
    startHour?: number;
    endHour?: number;
}

export interface CalendarGridProps {
    start: DateTime;
    end: DateTime;
    windows: BookingWindow[];
    noWindowsText?:string;
    interval?: number;
    onSelect?: (time: BookingWindow) => void;
}

const CalendarGrid: React.FC<CalendarGridProps> = ({ timeZone, start, end, windows, noWindowsText, interval = 30, onSelect }) => {

    const preferedColor = '#ffcfdd';
    const availableColor = '#d1ecf4';

    const handleSlotClick = useCallback((window:BookingWindow) => {
        onSelect && onSelect(window)
    },[onSelect]);

    const timeSlots = generateTimeSlots(interval);

    const days = useMemo((): DateTime[] => {
        const dates: DateTime[] = [];
        let currentDate = DateTime.fromObject({year:start.year, month:start.month, day:start.day, hour:0, minute:0, second:0});
        while (currentDate <= end) {
            dates.push(currentDate);
            currentDate = currentDate.plus({ days: 1 });
        }
        return dates;
    }, [start, end]);

    const windowByDay = useMemo(() => {
        const dates: BookingWindow[][] = [];
        for (let i = 0; i < days.length; i++) {
            const lday = days[i].day;
            dates.push(windows.filter((t) => { return t.localTime.day == lday }));
        }
        return dates;
    }, [windows, days]);

    const calendarGridComponent = useMemo(() => {
        const currentTime = DateTime.now().setZone(timeZone);
        console.log(currentTime);
        const showEmptyText = noWindowsText?.trim() && windows?.length == 0;
        return (<Grid
            container
            spacing={0.5}
            sx={{
                padding: 2,
                minWidth: "900px",
                border: "1px solid #ccc",
            }}
        >
            {/* Header Row: Zone */}
            <Grid
                item
                xs={1.5}
                key={'eet0'}
                sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    backgroundColor: "#f5f5f5",
                    padding: "10px",
                    border: "1px solid #ddd",
                }}
            >
                <Typography variant="h6">{currentTime.toFormat('ZZZZ')}</Typography>
            </Grid>
            {/* Header Row: Days */}
            {
                days.map((day, index) => (
                    <Grid
                        item
                        xs={(12 - 1.5) / days.length}
                        key={index}
                        sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                            backgroundColor: "#f5f5f5",
                            padding: "10px",
                            border: "1px solid #ddd",
                        }}
                    >
                        <Typography variant="h6">{day.toISODate()}</Typography>
                    </Grid>
                ))}
                {
                    showEmptyText && <Grid item xs={12}>
                        <Paper
                            sx={{
                                padding: 1,
                                textAlign: 'center',
                                borderBottom: '1px solid #ddd',
                                minHeight: '80px',
                                width:'100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'white'
                            }}
                        >
                        <Typography variant="body1">{noWindowsText}</Typography>
                        </Paper>
                    </Grid>
                }


            {/* Time Slots */}
            {!showEmptyText && timeSlots.map(({ hour, minutes }, index) => (
                <React.Fragment key={index}>
                    <Grid
                        item
                        xs={1.5}
                        sx={{
                            textAlign: "right",
                            paddingRight: 2,
                            fontWeight: "bold",
                            backgroundColor: "#fafafa",
                            borderBottom: "1px solid #ddd",
                            padding: "10px",
                        }}
                    >
                        <Typography variant="body2">{currentTime.set({minute:minutes, hour:hour}).toFormat("hh:mm a")}</Typography>
                    </Grid>

                    {/* Time Slots for Each Day */}
                    {days.map((day, dayIndex) => {
                        const dt = DateTime.fromObject({year:day.year, month:day.month, day:day.day, minute:minutes, hour:hour}, {zone:timeZone});
                        if(dt < currentTime) {
                            return <Grid item xs={(12 - 1.5) / days.length} key={dayIndex}>
                                <Paper sx={{ padding: 1, cursor:'not-allowed', minHeight: '40px', display: 'flex', backgroundColor: 'white' }}/>
                            </Grid>
                        }

                        const slot = windowByDay[dayIndex].find((dy) => dy.localTime.hour == hour && dy.time.minute == minutes);
                        const isAvailable = slot != undefined;
                        return (<Grid item xs={(12 - 1.5) / days.length} key={dayIndex}>
                            <Paper
                                sx={{
                                    padding: 1,
                                    textAlign: 'center',
                                    cursor: isAvailable ? 'pointer' : 'not-allowed',
                                    borderBottom: '1px solid #ddd',
                                    minHeight: '40px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: isAvailable ? slot.hasPrefered ? preferedColor : availableColor : '#e0e0e0',
                                    "&:hover": { backgroundColor: isAvailable ? "#1976d210" : "#e0e0e0" },
                                }}
                                onClick={() => isAvailable && handleSlotClick(slot)}
                            >
                                {dt.toFormat("hh:mm a")}
                            </Paper>
                        </Grid>)
                    })}
                </React.Fragment>
            ))}
        </Grid>)
    }, [timeSlots, windowByDay, noWindowsText, days, handleSlotClick, windows?.length]);



    return (
        <Box sx={{ maxWidth: "100%" }}>
            {calendarGridComponent}
        </Box>
    );
};

export default CalendarGrid;
