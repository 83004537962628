import * as React from 'react';
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { InactivityProvider } from "../hooks/useInactivity";

interface Props {
    children: JSX.Element | JSX.Element[];
}

const ProtectedRoute = ({ children }: Props): JSX.Element | null => {
    const auth = useAuth();
    const location = useLocation();
    const [isActive, setIsActive] = React.useState<boolean | null>(null);
    const [redirect, setRedirect] = React.useState<string | null>();

    React.useEffect(() => {
        const fetch = async () => {
            setIsActive(auth && (await auth.isActive()) || false);
        };
        // 45 sec assuming 1 min token lifetime
        const interval = setInterval(fetch, 45000); 
        fetch().catch(console.error);
        return () => {
            clearInterval(interval);
        }
    }, [auth]);

    const onTimeout = React.useCallback((redirectPath?:string)=> {
        auth?.logout();
        setRedirect(redirectPath)
        setIsActive(false);
    },[auth])

    return (isActive)
        ? <InactivityProvider onTimeout={onTimeout}>{children}</InactivityProvider>
        : (isActive == false
            ? <Navigate to={ redirect ? redirect : "/auth/login"} replace state={{ from: location }} />
            : null
        )
};

export default ProtectedRoute;