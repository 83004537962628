import * as React from 'react';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import {  Box, Typography, Stack, Button,} from '@mui/material';

export type ErrorLoaderData = [string];

export const loader = async ({ params, request }: LoaderFunctionArgs): Promise<ErrorLoaderData> => {

    const searchParams = new URL(request.url).searchParams;
    const type = params.type || searchParams.get('type') || '';
    return [type];
}

const ErrorNoticePage = (): JSX.Element => {
    const [type] = useLoaderData() as ErrorLoaderData;
    
    const showScreenDialog = React.useCallback((errorTitle:string, errorText:string|string[], buttonText?:string, callback?:()=>void) => {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <Box width={'60%'} height={'60%'} display="flex" justifyContent="center" alignItems="center"
                    border={1} borderRadius={2} boxShadow={1} p={2}>
                    <Stack direction={'column'}>
                        <Typography paddingBottom={'0.5em'} variant="h4" align="center"> {errorTitle} </Typography>
                        {
                            Array.isArray(errorText) ?
                                errorText.map((e, index) => { return <Typography key={`txt-${index}`} variant="h6" align="center">{e}</Typography> })
                                : <Typography variant="h6" align="center"> {errorText} </Typography>
                        }
                        <Box height='1em'/>
                        { callback ? <Button variant='contained' onClick={callback}>{buttonText}</Button> : <></> }
                    </Stack>
                </Box>
            </Box>
        );
    },[]);


    const noticeComponent = React.useMemo(() => {
        if(type == 'embeddedTimeout') {
            return showScreenDialog('Session Has Timed Out', [
                'The session with ZiphyOutreach expired due to inactivity.',
                'Please login again via ZiphyAdmin. Close this tab.'],
                'Close Window',
                () => { window.close(); }
            )
        }
        return showScreenDialog('Error', 'An unknown error has occured. Please try refreshing to continue.');
    },[type, showScreenDialog])


    return <>
        { noticeComponent }
    </>
}

export default ErrorNoticePage;