import * as React from 'react';
import { useCallback, useState } from 'react';
import { Client, Patient, Plan, Gender, PatientStatus, AssignmentInfo, ParentField, CustomFields as CCustomFields} from '../../../models/core';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField, Typography, Grid, Divider, IconButton, Tooltip, Stack} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { GridFormItem } from '../../GridForm';
import { UnknownLanguage, Genders, Languages, LoBs, PatientStatuses } from '../../../utils/enums';
import CustomFields from '../../CustomFields';
import { DateTime } from 'luxon';
import { EnumField, AutocompleteField } from '../../fields';
import { useAuth } from '../../../hooks/useAuth';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import debounce from 'lodash.debounce';
import SearchIcon from '@mui/icons-material/Search';
import ZiphyPatientSearchDialog from '../ZiphyPatientSearchDialog';
import useDialog from '../../../hooks/useDialog';

export enum PatientSection {
    Demographics = 'Demographics',
    Contacts = 'Contacts',
    Insurance = 'Insurance',
    Client = 'Client',
    Provider = 'Provider',
    Custom = 'Custom'
}

interface PatientEditorProps {
    patient?: Patient | null;
    patientState?: Partial<Patient>;
    client: Client;
    plans: Plan[];
    sections?:PatientSection[];
    readOnly?: boolean;
    onUpdateState?:(hasErrors:boolean, patient:Patient, changes:Partial<Patient>) => void;
    triggerPatientChange?:(patient:Patient)=>void;
}

const PatientEditorView = ({ client, patient, patientState, plans, sections,
    readOnly, onUpdateState, triggerPatientChange }: PatientEditorProps): JSX.Element => {
    const [activePatient, _setActivePatient] = useState(patient);
    const [activeClient, _setActiveClient] = useState(client);
    const [errors, _setErrors] = useState<Record<string, boolean>>({});
    const {show:showPS, hide:hidePS, open:isPsOpen} = useDialog();

    const [patientChanges, setPatientChanges] = useState<Partial<Patient>>(patientState || {});

    const hasField = React.useCallback((key:keyof Patient) => { return patientChanges[key] !== undefined; }, [patientChanges])
    const getField = React.useCallback((key:keyof Patient) => { return patientChanges[key]; }, [patientChanges])
    const setField = React.useCallback((value:Partial<Patient>) => { setPatientChanges((prev) => { return { ...prev, ...value }}); }, [])

    const getCurrentValue = React.useCallback((key:keyof Patient, defaultValue:undefined|string|string[]|number|CCustomFields|AssignmentInfo) => {
        return hasField(key) ? getField(key) : defaultValue;
    },[hasField, getField])

    const auth = useAuth();

    const layoutCustomFields = React.useMemo(() => activeClient?.layout.patientFields.filter(f => !f.field) || [], [activeClient]);

    const parseAge = useCallback((date:DateTime|null) => {
        if(date === null) { return ''; }
        let ageNow = 0;
        try {
            const today = DateTime.now();
            ageNow = today.year - date.year;
            const month = today.month - date.month;
            if (month < 0 || (month === 0 && today < date)) {
                ageNow--;
            }
        } catch (error) { console.warn(error); }
        return Number.isNaN(ageNow) ? '' : ageNow.toString();
    },[]);

    const checkRenderSection = useCallback((section:PatientSection, array:PatientSection[], def = true) => {
        return array?.length == 0 ? def : array?.findIndex((s) => section == s) >= 0;
    },[]);

    React.useEffect(() => {
        _setActivePatient(patient);
        _setActiveClient(client);
        setPatientChanges(patientState || {});
    }, [patient, client, patientState])

    const disabledFields = React.useMemo(() => (
        activeClient?.layout.patientFields.filter(f => f.state == 'disabled').flatMap(f => f.field ? [f.field] : []) || []
    ), [activeClient]);

    const layoutCustomSectionFields = React.useMemo(():{ [key: string]: ParentField[] } => {
        const sections: { [key: string]: ParentField[] } = {};
        activeClient?.layout.patientFields.forEach(f => {
            if(f.section) {
                if (!sections[f.section])
                    sections[f.section] = [];
                sections[f.section].push(f);
            }
        });
        return sections;
    }, [activeClient]);

    const _onMergeData = React.useCallback(() => {
        onUpdateState && onUpdateState(Object.values(errors).includes(true), patient || {} as Patient, patientChanges);
        console.log(patientChanges);
    },[patient, patientChanges, onUpdateState, errors])
    
    const debouncedMergeCallback = React.useMemo( () => debounce(_onMergeData, 100), [_onMergeData]);
    React.useEffect(() => {
        debouncedMergeCallback();
        return () => { debouncedMergeCallback.cancel(); };
    }, [debouncedMergeCallback]);

    const checkErrorStatusString = React.useCallback((key:string, required:boolean, value?:string) => {
        if(!required) { return false; }
        const status = !value || value.length == 0;
        const error = errors[key];
        if(error == undefined && status == false) { return false;   }
        if(status != error) {   errors[key] = status;   }
        return status;
    }, [errors]);

    const onCustomFieldChange = useCallback((value: { [k: string]: string | number | Date | boolean | undefined }) => {
        const cf = getCurrentValue('customFields', {} as CCustomFields) as CCustomFields;
        const et = Object.fromEntries(Object.entries(value).map(([k, v]) => [k, v || '']));
        const nCF = {...cf, ...et};
        setField({'customFields':nCF})
    }, [getCurrentValue, setField]);

    const generateCustomSectionItems = React.useCallback((section:PatientSection) => {
        const lsections:ParentField[] = layoutCustomSectionFields[section];
        if(!lsections) {
            return <></>;
        }
        const pcc = activePatient?.customFields || {};
        const customFieldKeys = Object.keys(pcc);
        const ucFields = getCurrentValue('customFields', {} as CCustomFields) as CCustomFields;

        function getCCValue(key:string) {
            return Object.keys(ucFields).includes(key) ? ucFields[key] : pcc[key];
        }

        const sectionFields = lsections.filter((s) => s.field && customFieldKeys.includes(s.field) && s.state != 'disabled').map((key, i) => 
            (<GridFormItem key={`lcs-${section}-${i}`} xs={4}>
                <TextField label={key.label} name={key.field} value={key.field ? getCCValue(key.field) as string : ''} variant='standard'/>
            </GridFormItem>
        )) || [];
        return (
            sectionFields.length > 0 ? <Grid container spacing={2}>
                <GridFormItem xs={12}><Box sx={{ height: '0.5em' }} /></GridFormItem>
                <GridFormItem item xs={12} padding='4px 0px 0px 0px'><Divider variant="middle" /></GridFormItem>
                { sectionFields }
                <GridFormItem item xs={12} padding='4px 0px 0px 0px'><Divider variant="middle" /></GridFormItem>
            </Grid> : <></>);
    }, [activePatient?.customFields, layoutCustomSectionFields, getCurrentValue]);

    const copyAddressString = React.useCallback(() => {
        const address = getCurrentValue('address', activePatient?.address);
        const address2 = getCurrentValue('address2', activePatient?.address2);
        const city = getCurrentValue('city', activePatient?.city);
        const state = getCurrentValue('state', activePatient?.state);
        const zip = getCurrentValue('zip', activePatient?.zip);
        navigator.clipboard.writeText(`${address} ${address2}, ${city}, ${state}. ${zip}`);
    },[getCurrentValue, activePatient?.address, activePatient?.address2, activePatient?.city, activePatient?.state, activePatient?.zip])

    const zcmFindComponents = React.useMemo<JSX.Element>(() => {
        return isPsOpen ? <ZiphyPatientSearchDialog open={isPsOpen} client={activeClient || {} as Client}
            patient={activePatient || {} as Patient}
            onClose={hidePS}
            onUpdate={ (id, updatedPatient) => {
                    if(id && id != '') {
                        setField({'zcmId':id})
                        _setActivePatient(updatedPatient);
                        triggerPatientChange && triggerPatientChange(updatedPatient);
                    }
                }
            }
            onCreatePatient={hidePS}
        /> : <></>
    }, [hidePS, isPsOpen, activeClient, activePatient])

    //  Demographics
    const demographicsComponents = React.useMemo<JSX.Element>(() => {
        const firstName = getCurrentValue('firstName', activePatient?.firstName) as string;
        const lastName = getCurrentValue('lastName', activePatient?.lastName) as string;
        const dob = getCurrentValue('dob', activePatient?.dob) as string;
        const status = getCurrentValue('status', activePatient?.status) as string || PatientStatus.ACTIVE.toString();
        const zcmID = getCurrentValue('zcmId', activePatient?.zcmId) as string;

        return (
            checkRenderSection(PatientSection.Demographics, sections || []) ? (
            <GridFormItem xs={12}>
            <Grid container spacing={2}>
                <GridFormItem xs={4}>
                    <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start'}}>Demographics</Typography>
                </GridFormItem>
                <GridFormItem xs={2}>
                    <Stack direction={'row'}>
                        <TextField name='zcmid' label='ZCM ID' value={zcmID} 
                            variant='standard' inputProps={{ readOnly: true, }} InputLabelProps={{ shrink: !!zcmID }} />
                        <Tooltip title='Search for Pateint in ZCM DB'>
                            <span>
                                <IconButton size='small' onClick={showPS} sx={{marginTop:'12px', paddingRight:'5px'}}><SearchIcon/></IconButton>
                            </span>
                        </Tooltip>
                    </Stack>
                </GridFormItem>
                <GridFormItem xs={3}>
                    <EnumField name='assignTo' label='Assigned To' value={
                            (getCurrentValue('assignment', undefined) as AssignmentInfo || activePatient?.assignment)?.assignedTo || 'None'
                        } 
                        onChange={(_,v) => {
                            const as = {
                                assignedDate: DateTime.now().toString(),
                                assignedTo:v||'None',
                                assignedBy:auth?.user?.name, 
                                assignedById:auth?.user?.id
                            } as AssignmentInfo;
                            setField({assignment:as});
                        }}
                        options={
                            activeClient?.repNames?.includes('None')
                                ? (activeClient?.repNames || []) 
                                : ['None', ...(activeClient?.repNames || [])]
                        } required readOnly={readOnly} />
                </GridFormItem>
                <GridFormItem xs={1}/>
                <GridFormItem xs={2}>
                    <EnumField name='status' label='Status' value={status} 
                        onChange={(_,v) => setField({status:v as PatientStatus}) }
                        error={ checkErrorStatusString('status', true, status) }
                        options={PatientStatuses} required readOnly={readOnly} />
                </GridFormItem>
                <GridFormItem xs={12}>
                <Divider/>
                </GridFormItem>
                <GridFormItem xs={6}>
                    <TextField name='firstName' label='First Name' value={firstName}
                        error={ checkErrorStatusString('firstName', true, firstName) }
                        onChange={ (ev) => setField({firstName:ev.target.value})}
                        inputProps={{ readOnly: readOnly, }} variant='standard' required />
                </GridFormItem>
                <GridFormItem xs={6}>
                    <TextField name='lastName' label='Last Name' value={lastName}
                        error={ checkErrorStatusString('lastName', true, lastName) }
                        onChange={ (ev) => setField({lastName:ev.target.value})}
                        inputProps={{ readOnly: readOnly, }} variant='standard' required />
                </GridFormItem>
                <GridFormItem xs={3}>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker label='Date of Birth' inputFormat='yyyy-MM-dd'
                            value={ DateTime.fromISO(dob) }
                            renderInput={
                                (params) => <TextField name='dob' required {...params} 
                                error={checkErrorStatusString('dob', true, dob)}
                                variant='standard' helperText='Format: YYYY-MM-DD' />
                            }
                            onChange={(e) => setField({dob:e?.toISODate()||undefined})
                            } readOnly={readOnly} />
                    </LocalizationProvider>
                </GridFormItem>
                <GridFormItem xs={1}><Box sx={{ height: '1em' }} /></GridFormItem>
                <GridFormItem xs={1}>
                    <TextField name='age' label='Age' value={parseAge(DateTime.fromISO(getCurrentValue('dob', activePatient?.dob) as string))} variant='standard' inputProps={{ readOnly: true, }} />
                </GridFormItem>
                <GridFormItem xs={1}><Box sx={{ height: '1em' }} /></GridFormItem>
                <GridFormItem xs={2}>
                    <EnumField name='gender' label='Sex Assigned Birth' value={getCurrentValue('gender', activePatient?.gender) as string}
                        onChange={(n,v) => setField({gender:v as Gender})}
                        options={Genders} required readOnly={readOnly} />
                </GridFormItem>
                <GridFormItem xs={1} ><Box sx={{ height: '1em' }} /></GridFormItem>
                <GridFormItem xs={3}>
                    <EnumField name='language' label='Language' value={ getCurrentValue('language', activePatient?.language) as string||UnknownLanguage}
                        onChange={(_,v) => setField({language:v as string||UnknownLanguage})}
                        options={Languages} required readOnly={readOnly} />
                </GridFormItem>
            </Grid>
            {   generateCustomSectionItems(PatientSection.Demographics) }
            <GridFormItem xs={12}><Box sx={{ height: '2em' }} /></GridFormItem>
            </GridFormItem>) : <></>
        )
    },[parseAge, readOnly, sections, checkRenderSection, checkErrorStatusString,
        activeClient?.repNames, generateCustomSectionItems, auth?.user?.id, auth?.user?.name,
        getCurrentValue, setField, activePatient, showPS]);

    //  Contacts
    const contactComponents = React.useMemo<JSX.Element>(() => {
            const phone1 = getCurrentValue('phone', activePatient?.phone);
            const phone2 = getCurrentValue('phone2', activePatient?.phone2);
            const guardianPhone = getCurrentValue('guardianPhone', activePatient?.guardianPhone)
            const address = getCurrentValue('address', activePatient?.address)
            const address2 = getCurrentValue('address2', activePatient?.address2)

            const city = getCurrentValue('city', activePatient?.city)
            const state = getCurrentValue('state', activePatient?.state)
            const zip = getCurrentValue('zip', activePatient?.zip)
            const county = getCurrentValue('county', activePatient?.county)
            
            return (
                checkRenderSection(PatientSection.Contacts, sections || []) ? (
                <GridFormItem xs={12}>
                <Grid container spacing={2}>
                    <GridFormItem xs={11}>
                        <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>Contacts & Address</Typography>
                    </GridFormItem>
                    <GridFormItem xs={1}>
                        <Tooltip title='Copy Address'>
                        <IconButton onClick={copyAddressString}><ContentCopyIcon/></IconButton>
                        </Tooltip>
                    </GridFormItem>

                    <GridFormItem xs={4}>
                        <TextField name='phone' label='Phone' value={phone1}
                            error={checkErrorStatusString('phone', true, phone1 as string)}
                            onChange={ (ev) => setField({phone:ev.target.value})}
                            inputProps={{ readOnly: readOnly, }} variant='standard' />
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField name='phone2' label='Alt Phone' value={phone2}
                            error={checkErrorStatusString('phone2', false, phone2 as string)} disabled={disabledFields.includes('phone2')} 
                            onChange={ (ev) => setField({phone2:ev.target.value})}
                            inputProps={{ readOnly: readOnly, }} variant='standard' />
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField name='email' label='Email' value={getCurrentValue('email', activePatient?.email)} 
                            disabled={disabledFields.includes('email')}
                            onChange={ (ev) => setField({email:ev.target.value})}
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth />
                    </GridFormItem>
                    <GridFormItem xs={12}><Box sx={{ height: '1em' }} /></GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField name='guardianName' label='Guardian Name' disabled={disabledFields.includes('guardianName')} 
                            value={getCurrentValue('guardianName', activePatient?.guardianName)} 
                            onChange={ (ev) => setField({guardianName:ev.target.value})}
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth />
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField name='guardianPhone' label='Guardian Phone' disabled={disabledFields.includes('guardianPhone')} 
                            inputProps={{ readOnly: readOnly, }} value={guardianPhone}
                            error={checkErrorStatusString('guardianPhone', false, guardianPhone as string)}
                            onChange={ (ev) => setField({guardianPhone:ev.target.value})}
                            variant='standard' fullWidth/>
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField name='guardianEmail' label='Guardian Email' disabled={disabledFields.includes('guardianEmail')} 
                            inputProps={{ readOnly: readOnly, }} value={getCurrentValue('guardianEmail', activePatient?.guardianEmail)}
                            onChange={ (ev) => setField({guardianEmail:ev.target.value})}
                            variant='standard' fullWidth />
                    </GridFormItem>
                    <GridFormItem xs={12}><Box sx={{ height: '1em' }} /></GridFormItem>
                    <GridFormItem xs={12}>
                        <TextField name='address' label='Address' value={address}
                            error={checkErrorStatusString('address', true, address as string)}
                            onChange={ (ev) => setField({address:ev.target.value})}
                            inputProps={{ readOnly: readOnly, }} variant='standard' required fullWidth />
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <TextField name='address2' label='Address 2' value={address2} disabled={disabledFields.includes('address2')} 
                            error={checkErrorStatusString('address2', false, address as string)}
                            onChange={ (ev) => setField({address2:ev.target.value})}
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth/>
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <TextField name='county' label='County' value={getCurrentValue('county', activePatient?.county)} 
                            disabled={disabledFields.includes('county')} 
                            error={checkErrorStatusString('county', false, county as string)}
                            onChange={ (ev) => setField({county:ev.target.value})}
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth />
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField name='city' label='City' value={city} 
                            error={checkErrorStatusString('city', true, city as string)}
                            onChange={ (ev) => setField({city:ev.target.value})}
                            inputProps={{ readOnly: readOnly, }} variant='standard' required />
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField name='state' label='State' value={state}
                            error={checkErrorStatusString('state', true, state as string)}
                            onChange={ (ev) => setField({state:ev.target.value})}
                            inputProps={{ readOnly: readOnly, }} variant='standard' required />
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField name='zip' label='ZIP' value={zip}
                            error={checkErrorStatusString('zip', true, zip as string)}
                            onChange={ (ev) => setField({zip:ev.target.value})}
                            inputProps={{ readOnly: readOnly, }} variant='standard' required />
                    </GridFormItem>
                </Grid>
                {   generateCustomSectionItems(PatientSection.Contacts) }
                <GridFormItem xs={12}><Box sx={{ height: '2em' }} /></GridFormItem>
                </GridFormItem>) : <></>
            )
        },[checkRenderSection, readOnly, sections, disabledFields, generateCustomSectionItems,
            activePatient, getCurrentValue, setField, copyAddressString, checkErrorStatusString]);

        //  Insurance
        const insuranceComponents = React.useMemo<JSX.Element>(() => {
            const medicaidId = getCurrentValue('medicaidId', activePatient?.medicaidId) as string;
            const medicareId = getCurrentValue('medicaidId', activePatient?.medicaidId) as string;
            const planId = getCurrentValue('planId', activePatient?.planId) as string || '';
            const subProgram = getCurrentValue('subProgram', activePatient?.subProgram) as string;
            const lineOfBusiness = getCurrentValue('lineOfBusiness', activePatient?.lineOfBusiness) as string;

            return (
                checkRenderSection(PatientSection.Insurance, sections || []) ? (
                <GridFormItem xs={12}>
                <Grid container spacing={2}>
                    <GridFormItem xs={12}>
                        <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>Insurance</Typography>
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <TextField name='medicaidId' label='Medicaid ID' value={medicaidId}
                            error={checkErrorStatusString('medicaidId', false, medicaidId as string)}
                            onChange={ (ev) => setField({medicaidId:ev.target.value}) }
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth/>
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <TextField name='medicareId' label='Medicare ID' value={medicareId}
                            error={checkErrorStatusString('medicareId', false, medicareId as string)}
                            onChange={ (ev) => setField({medicareId:ev.target.value}) }
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth/>
                    </GridFormItem>
                    <GridFormItem xs={12}>
                    <FormControl
                        variant='standard'
                        required
                        error={checkErrorStatusString('planId', false, planId)}
                        fullWidth
                        sx={{ visibility: plans.length > 0 ? 'visible' : 'hidden' }}>
                        <InputLabel>Plan</InputLabel>
                        <Select name='planId' value={planId}
                            onChange={(ev, _n) => setField({planId:ev.target.value})}
                            readOnly={readOnly}>
                            {plans.map((plan, i) => (
                                <MenuItem key={`item-${i}`} value={plan._id}>{plan.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <TextField name='subProgram' label='Sub-Program' value={subProgram}
                            error={checkErrorStatusString('subProgram', false, subProgram as string)}
                            disabled={disabledFields.includes('subProgram')} 
                            onChange={ (ev) => setField({subProgram:ev.target.value})}
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth/>
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <AutocompleteField name='line-of-business' label='Line of Business' 
                            value={lineOfBusiness as string}
                            error={checkErrorStatusString('lineOfBusiness', false, lineOfBusiness as string)}
                            onChange={(_,v) => setField({lineOfBusiness:v?.toString()})}
                            options={LoBs} required readOnly={readOnly}
                            disabled={disabledFields.includes('lineOfBusiness')}/>
                    </GridFormItem>
                </Grid>
                {   generateCustomSectionItems(PatientSection.Insurance) }
                <GridFormItem xs={12}><Box sx={{ height: '2em' }} /></GridFormItem>
                </GridFormItem>) : <></>
            )
        },[checkRenderSection, readOnly, sections, disabledFields, plans, generateCustomSectionItems,
            getCurrentValue, setField, activePatient, checkErrorStatusString]);

        //  Client
        const clientComponents = React.useMemo<JSX.Element>(() => {
            const refId = getCurrentValue('refId', activePatient?.refId) as string;
            const risk = getCurrentValue('risk', activePatient?.risk);
            const riskCategory = getCurrentValue('riskCategory', activePatient?.riskCategory) as string;

            return (
                checkRenderSection(PatientSection.Client, sections || []) ? (
                <GridFormItem xs={12}>
                <Grid container spacing={2}>
                    <GridFormItem xs={12}>
                        <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>Client</Typography>
                    </GridFormItem>
                    <GridFormItem xs={12}>
                        <TextField name='refId' label='Client ID' value={refId}
                            error={checkErrorStatusString('refId', true, refId)}
                            onChange={ (ev) => setField({refId:ev.target.value})}
                            inputProps={{ readOnly: readOnly, }} variant='standard' required fullWidth />
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <TextField name='risk' label='Risk' value={risk} 
                            error={checkErrorStatusString('risk', false, risk as string)} disabled={disabledFields.includes('risk')} 
                            onChange={(e) => setField({risk:Number.parseFloat(e.target.value || '0')})}
                            inputProps={{ readOnly: readOnly, }} variant='standard' />
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <TextField name='riskCategory' label='Risk Category' value={riskCategory}
                            error={checkErrorStatusString('riskCategory', false, riskCategory)} disabled={disabledFields.includes('riskCategory')} 
                            onChange={ (ev) => setField({riskCategory:ev.target.value}) }
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth />
                    </GridFormItem>
                </Grid>
                {   generateCustomSectionItems(PatientSection.Client) }
                <GridFormItem xs={12}><Box sx={{ height: '2em' }} /></GridFormItem>
                </GridFormItem>) : <></>
            )
        },[checkRenderSection, readOnly, sections, getCurrentValue, setField, activePatient,
            disabledFields, generateCustomSectionItems, checkErrorStatusString]);

        const providerComponents = React.useMemo<JSX.Element>(() => {
            const pcpName = getCurrentValue('pcpName', activePatient?.pcpName) as string;
            const pcpPhone = getCurrentValue('pcpPhone', activePatient?.pcpPhone) as string;
            const pcpNPI = getCurrentValue('pcpNPI', activePatient?.pcpNPI) as string;
            const practiceName = getCurrentValue('practiceName', activePatient?.practiceName) as string;
            const groupName = getCurrentValue('groupName', activePatient?.groupName) as string;
            const caseManager = getCurrentValue('caseManager', activePatient?.caseManager) as string;
            const caseManagerPhone = getCurrentValue('caseManagerPhone', activePatient?.caseManagerPhone) as string;

            return (
                checkRenderSection(PatientSection.Provider, sections || []) ? (
                <GridFormItem xs={12}>
                <Grid container spacing={2}>
                    <GridFormItem xs={12}>
                        <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>Provider / Case Manager</Typography>
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField name='pcpName' label='Provider Name' value={pcpName}
                            error={checkErrorStatusString('pcpName', false, pcpName)} disabled={disabledFields.includes('pcpName')} 
                            onChange={ (ev) => setField({pcpName:ev.target.value})}
                            inputProps={{ readOnly: readOnly, }} variant='standard' />
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField name='pcpPhone' label='Provider Phone' value={pcpPhone}
                            error={checkErrorStatusString('pcpPhone', false, pcpPhone)} disabled={disabledFields.includes('pcpPhone')} 
                            onChange={ (ev) => setField({pcpPhone:ev.target.value})}
                            inputProps={{ readOnly: readOnly, }} variant='standard' />
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField name='pcpNPI' label='Provider NPI' value={pcpNPI}
                            error={checkErrorStatusString('pcpNPI', false, pcpNPI)} disabled={disabledFields.includes('pcpNPI')} 
                            onChange={ (ev) => setField({pcpNPI:ev.target.value})}
                            inputProps={{ readOnly: readOnly, }} variant='standard' />
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <TextField name='practiceName' label='Practice Name' value={practiceName}
                            error={checkErrorStatusString('practiceName', false, practiceName)} disabled={disabledFields.includes('practiceName')} 
                            onChange={ (ev) => setField({practiceName:ev.target.value})}
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth />
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <TextField name='groupName' label='Group Name' value={groupName}
                            error={checkErrorStatusString('groupName', false, groupName)} disabled={disabledFields.includes('groupName')} 
                            onChange={ (ev) => setField({groupName:ev.target.value})}
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth/>
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <TextField name='caseManager' label='Case Manager' value={caseManager}
                            error={checkErrorStatusString('caseManager', false, caseManager)} disabled={disabledFields.includes('caseManager')} 
                            onChange={ (ev) => setField({caseManager:ev.target.value})}
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth />
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <TextField name='caseManagerPhone' label='Case Manager Phone'
                            value={caseManagerPhone}
                            error={checkErrorStatusString('caseManagerPhone', false, caseManagerPhone)}
                            disabled={disabledFields.includes('caseManagerPhone')} 
                            onChange={ (ev) => setField({caseManagerPhone:ev.target.value})}
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth/>
                    </GridFormItem>
                </Grid>
                {   generateCustomSectionItems(PatientSection.Provider) }
                <GridFormItem xs={12}><Box sx={{ height: '2em' }} /></GridFormItem>
                </GridFormItem>) : <></>
            )
        },[checkRenderSection, readOnly, sections, disabledFields, generateCustomSectionItems, getCurrentValue,
            setField, activePatient, checkErrorStatusString]);

        const customComponents = React.useMemo<JSX.Element>(() => {
            const enabled = checkRenderSection(PatientSection.Custom, sections || []);
            if(!enabled) {
                return <></>
            }
            const ccp = activePatient?.customFields || {};
            const cf = getCurrentValue('customFields', {} as CCustomFields) as CCustomFields;
            const workingCF = {...ccp, ...cf}
            return (
                layoutCustomFields.length > 0 && !readOnly ? (
                <GridFormItem xs={12}>
                <Grid container spacing={2}>
                    <GridFormItem xs={12}>
                        <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>Custom Fields</Typography>
                    </GridFormItem>
                    <GridFormItem xs={12}>
                        <CustomFields value={workingCF || {}} fields={layoutCustomFields} onChange={onCustomFieldChange} />
                    </GridFormItem>
                </Grid>
                </GridFormItem>) : <></>
            )
        },[onCustomFieldChange, checkRenderSection, layoutCustomFields, activePatient?.customFields, readOnly, sections, getCurrentValue]);

        const customReadOnlyComps = React.useMemo<JSX.Element>(() => {
            const enabled = checkRenderSection(PatientSection.Custom, sections || []);
            if(!enabled) {
                return <></>
            }
            const ccp = activePatient?.customFields || {};
            const fieldKeys = (activeClient.layout.patientFields.filter((l) => l && (l.field || l.section))).map((f) => f?.field);
            const keysWithoutFields = Object.keys(ccp).filter((k) => !fieldKeys.includes(k)).map((key, i) => 
                (<GridFormItem key={`lc-${i}`} xs={4}>
                     <TextField label={key} name={key} value={ccp[key] as string} variant='standard'/>                           
                </GridFormItem>
            )) || []

            return (
                keysWithoutFields?.length > 0 && !readOnly ? (
                <GridFormItem xs={12}>
                <Grid container spacing={2}>
                    <GridFormItem xs={12}>
                        <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>Read Only</Typography>
                    </GridFormItem>
                    {
                        keysWithoutFields
                    }
                </Grid>
                </GridFormItem>) : <></>
            )
        },[checkRenderSection, activeClient.layout.patientFields, activePatient?.customFields, readOnly, sections]);

    return <Grid container spacing={2}>
        { demographicsComponents }{ contactComponents }{ insuranceComponents }{ clientComponents }
        { providerComponents }{ customComponents }{ customReadOnlyComps } { zcmFindComponents }
    </Grid>
}

export default PatientEditorView;