import * as React from 'react';
import { useCallback, useState } from 'react';
import FiltersPanel, { FilterField, FilterValue } from '../FiltersPanel';
import TransferList from './TransferList';
import MessagesBox, { Message } from './MessagesBox';
import { Box, BoxProps } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

interface SelectorTabProps<T> extends Omit<BoxProps,'onChange'|'defaultValue'> {
    fields: FilterField[];
    defaultValue: FilterValue;
    messages?: Message[];
    choosenItems: T[];
    onFetch: (filter: FilterValue) => Promise<T[]>;
    itemFn: (item: T) => React.ReactElement;
    eqFn: (x: T, y: T) => boolean;
    onChange?: (items: T[]) => void;
}

function SelectorTab<T>({fields, defaultValue, messages, choosenItems, onFetch, itemFn, eqFn, onChange, ...props}: SelectorTabProps<T>): JSX.Element {
    const [filterValue, setFilterValue] = useState(defaultValue);
    const itemsQuery = useQuery({
        queryKey: ['selector-'+(props.id||''), filterValue] as [string, FilterValue],
        queryFn: ({queryKey: [_, filterValue]}) => onFetch(filterValue)
    });
    const makeFilterValue = useCallback((value: { [k: string]: string | string[] }) => {
        function maybeJoin(x: string | string[]): string | string[] {
            return (x instanceof Array && x.includes('all')) ? ['all'] : x;
        }
        return Object.fromEntries(Object.entries(value).map(([k, v]) => [k, maybeJoin(v) || ['all']]));
    }, []);
    const onFiltersChange = useCallback((value: FilterValue) => {
        setFilterValue(makeFilterValue(value));
    }, [makeFilterValue]);

    return <Box sx={{width: '720px', height: '512px', padding: '0 24px', display: 'flex', flexDirection: 'column'}} {...props}>
        <FiltersPanel padding={0} marginBottom='16px' height='100px' direction='row' fields={fields} defaultValue={filterValue} onChange={onFiltersChange} />
        <TransferList items={itemsQuery.data||[]} loading={itemsQuery.isFetching} {...{choosenItems:choosenItems||[], itemFn, eqFn, onChange}} flexGrow={1} />
        <MessagesBox messages={messages||[]} />
    </Box>
}

export default SelectorTab;